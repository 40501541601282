import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Tabs, Tab, Typography, Box } from '@material-ui/core'
import sharedStyles from '../styles/global'
import { useState, useEffect } from 'react'
import withLocation from './withLocation'
import {
   getQueryStringParams,
   determineActiveTab,
   determineInitialTab,
} from '../services/utilityService'

function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box p={3}>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   wrapper: {
      marginBottom: '2vh',
   },
}))

const participateSubMenus = ({ selected, changed, location }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   function handleTabChange(evt, newTabIndex) {
      changed(evt, newTabIndex)
      setActiveTab(newTabIndex)
      const newTabParamValue = tabNames[newTabIndex].toLowerCase()
      if (typeof window !== 'undefined') {
         const newURL = `${window.location.origin}${
            window.location.pathname
         }?tab=${encodeURIComponent(newTabParamValue)}`
         window.history.pushState(
            { path: newURL, tabIndex: newTabIndex },
            null,
            newURL
         )
      }
      setTabParam(newTabParamValue)
   }

   const tabNames = ['LEADERSHIP', 'ORG CHART', 'PROFILES']
   const [tabParam, setTabParam] = useState('')
   const queryParams = getQueryStringParams()
   const [activeTab, setActiveTab] = useState(() =>
      determineInitialTab(location.href, tabNames)
   )

   useEffect(() => {
      determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
   }, [tabParam])

   return (
      <div className={css.wrapper}>
         <Tabs
            value={selected}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
         >
            {tabNames.map((tabName, idx) => {
               const tabStyles = [css.tabControl, css.sansProSemiBold]
               if (idx !== selected) {
                  tabStyles.push(css.nonActiveTab)
               }
               const tabClasses = tabStyles.join(' ')
               return (
                  <Tab
                     label={tabName}
                     key={`${tabName}-tab-${idx}`}
                     {...a11yProps(idx)}
                     className={tabClasses}
                  />
               )
            })}
         </Tabs>
      </div>
   )
}
export default withLocation(participateSubMenus)
