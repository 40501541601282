import React from 'react'
import Modal from './modal'
import RequestService from './requestServices'
import sharedStyles from '../styles/global'
import Markdown from 'react-markdown/with-html'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   subTitle: {
      textAlign: 'right',
      fontWeight: 'normal',
      textJustify: 'auto',
   },

   contactUsSpacing: {
      padding: theme.spacing(8, 0, 8, 0),
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'space-between',
      '& *': {
         margin: theme.spacing(2, 0),
      },
   },
   contactButton: {
      textAlign: 'center',
      margin: 'auto',
      '& button': {
         padding: theme.spacing(1.3, 6),
         border: '1px solid white',
         borderRadius: '8px',
         backgroundColor: 'white',
         cursor: 'pointer',
         fontWeight: 'bolder',
      },
   },
   ieGridGap: {
      marginRight: theme.spacing(2),
   },
   backgroundImageBox: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: 'white',
      marginLeft: theme.spacing(-15),
      marginRight: theme.spacing(-15),
      marginBottom: theme.spacing(4),
   },
}))

const BackgroundBoxComp = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const {
      subheader,
      paragraphText,
      imageInfo,
      fluidImage,
      textButton,
      backgroundBox,
   } = props

   const textSideClasses = [
      css.textSide,
      css.bulletStyling,
      fluidImage ? '' : css.spanTwoCols,
   ].join(' ')

   const [open, setOpen] = React.useState(false)
   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClose = () => {
      setOpen(false)
   }

   return (
      <>
         <div
            className={[css.backgroundImageBox].join(' ')}
            style={{
               backgroundImage: `url(${fluidImage.src})`,
            }}
         >
            {/*this class making a shade transparent with blue className={[css.imageGradient].join(' ')} */}
            <div className={[css.content, css.contactUsSpacing].join(' ')}>
               <h3
                  className={[
                     css.pt40,
                     css.sansProBold,
                     css.center,
                     css.uppercase,
                  ].join(' ')}
               >
                  {subheader}
               </h3>
               <p className={[css.pt22, css.center].join(' ')}>
                  <Markdown source={paragraphText} escapeHtml={false} />
               </p>
               <div className={css.contactButton}>
                  <button
                     className={[css.sansPro, css.pt22].join(' ')}
                     onClick={handleClickOpen}
                  >
                     {textButton}
                  </button>
               </div>
            </div>
         </div>
         <div className={[css.contactSection, css.openSans].join(' ')}>
            <Modal
               title={'Request Inquiry Form'}
               isOpen={open}
               handleClose={handleClose}
            >
               <RequestService></RequestService>
            </Modal>
         </div>
      </>
   )
}

export default BackgroundBoxComp
