import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ImageOverlay from './imageOverlay'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   headerSpacing: {
      paddingBottom: '0rem',
      paddingBottom: '0.3rem',
   },
   tagSpacing: {
      paddingBottom: '0.8rem',
   },
   publishSpacing: {
      paddingTop: '1.0rem',
   },
}))

const LargerNewsPanel = props => {
   const theme = useTheme()
   const classes = useStyles(theme)
   const article = props.article
   const cssSettings = {
      crop: false,
      container: {
         maxHeight: '375px',
         '&:hover': {
            '& > div': {
               background: 'rgba(29,55,135,0.85)',
            },
         },
      },
      text: {
         lineHeight: 1.25,
         paddingLeft: '15px',
      },
      image: {},
   }

   //-- handle missing header image
   if (article.Header === undefined || article.Header === 'null') {
      article.Header = { File: null }
   }

   return (
      <div>
         <Link to={`../news/${article.Slug}`}>
            <ImageOverlay
               image={article.Header?.File && article.Header.File.localFile ? article.Header.File.localFile : article.image1?.File.localFile ? article.image1.File.localFile  : article.missingImage}
               alt={article.Alt}
               css={cssSettings}
            >
               <div
                  className={[
                     classes.sansPro,
                     classes.pt20,
                     classes.bold,
                     classes.uppercase,
                     classes.opacity80,
                     classes.tagSpacing,
                  ].join(' ')}
               >
                  {article.tags.length > 0 ? article.tags[0].Name : ' '}
               </div>
               <div
                  className={[
                     classes.sansPro,
                     classes.pt45,
                     classes.bold,
                     classes.headerSpacing,
                  ].join(' ')}
               >
                  {article.Title}
               </div>
               <p
                  className={[
                     classes.sansPro,
                     classes.pt18,
                     classes.regular,
                     classes.opacity60,
                     classes.publishSpacing,
                  ].join(' ')}
               >
                  {article.Publish}
               </p>
            </ImageOverlay>
         </Link>
      </div>
   )
}

export default LargerNewsPanel
