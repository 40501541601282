import React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core'
import sharedStyles from '../styles/global'
import Layout from '../components/layout'
import RequestServices from '../components/requestServices'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'
import {
   getQueryStringParams,
   determineActiveTab,
   determineInitialTab,
} from '../services/utilityService'
import Markdown from 'react-markdown/with-html'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function getQueryVariable(variable) {
   if (typeof window !== 'undefined') {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
         var pair = vars[i].split('=')
         if (pair[0] == variable) {
            return pair[1]
         }
      }
   }
   return false
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   servicesPageHeader: {
      margin: '3rem 0rem 0.3rem 0rem',
   },
   tabControls: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
         marginBottom: theme.spacing(4),
      },
   },
   serviceHeader: {
      margin: theme.spacing(3, 0, 1, 0),
      [theme.breakpoints.up('md')]: {
         margin: theme.spacing(4, 0, 2, 0),
      },
   },
   panelRoot: {
      boxShadow: 'none',
      borderBottom: 'solid 1px #e4e4e4',
   },
   servicePanelContent: {
      paddingRight: theme.spacing(4),
      '& p': {
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
         paddingRight: theme.spacing(6),
      },
   },
   contactStyling: {
      marginBottom: theme.spacing(2),
   },
}))

function TabPanel(props) {
   const { children, value, index, ...other } = props
   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}
function expandPanel(panel) {
   const panelName = getQueryVariable('panel')
   var rtn = false
   panel == panelName ? (rtn = true) : (rtn = false)
   return rtn
}
function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}
const OurServices = ({ data, location }) => {
   const theme = useTheme()
   const css = useStyles(theme)
   const [expanded, setExpanded] = React.useState(false)
   const handleChange = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false)
   }
   function handleTabChange(evt, newTabIndex) {
      setActiveTab(newTabIndex)
      const newTabParamValue = tabNames[newTabIndex].toLowerCase()
      if (typeof window !== 'undefined') {
         const newURL = `${window.location.origin}${
            window.location.pathname
         }?tab=${encodeURIComponent(newTabParamValue)}`
         window.history.pushState(
            { path: newURL, tabIndex: newTabIndex },
            null,
            newURL
         )
      }
   }

   if (typeof window !== 'undefined') {
      window.addEventListener('popstate', function(data) {
         const state = data.state
         if (state && state.tabIndex >= 0) setActiveTab(data.state.tabIndex)
         else setActiveTab(0)
      })
   }

   const serviceInfo = data.strapiService.nodes[0]
   const formattedText = serviceInfo.FormattedText.data.FormattedText
   const title = serviceInfo.Title
   const tabNames = ['ABOUT', 'SERVICE INQUIRY FORM']

   const [tabParam, setTabParam] = useState('')
   const queryParams = getQueryStringParams(location.href)
   const [activeTab, setActiveTab] = useState(() =>
      determineInitialTab(location.href, tabNames)
   )
   useEffect(() => {
      determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
   }, [tabParam])
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
   const serviceHeaderStyles = [
      css.uppercase,
      css.sansProSemiBold,
      css.serviceHeader,
      isLargeScreen ? css.pt40 : css.pt30,
   ].join(' ')
   const serviceSubHeaderStyles = [
      css.bold,
      isLargeScreen ? css.pt26 : css.pt20,
   ].join(' ')
   const servicePanelContentStyles = [
      css.servicePanelContent,
      isLargeScreen ? css.pt22 : css.pt18,
   ].join(' ')
   return (
      <Layout>
         <SEO title="Participate" />
         <Helmet>
            <meta charSet="utf-8" />
         </Helmet>
         <ImageHeader
            title={`COMMUNICATIONS & NAVIGATION NETWORK SERVICES`}
            image={data.headerImage}
         ></ImageHeader>
         <div id="content" className={css.content}>
            <div className={css.tabControls}>
               <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
               >
                  {tabNames.map((tabName, idx) => {
                     const tabStyles = [css.tabControl, css.sansProSemiBold]
                     const tabLength = tabName.length
                     if (idx !== activeTab) {
                        tabStyles.push(css.nonActiveTab)
                     }
                     const tabClasses = tabStyles.join(' ')
                     return (
                        <Tab
                           label={tabName}
                           key={`${tabName}-tab-${idx}`}
                           {...a11yProps(idx)}
                           className={tabClasses}
                           style={tabLength < 8 ? { minWidth: '5%' } : {}}
                        />
                     )
                  })}
               </Tabs>
            </div>
            <TabPanel value={activeTab} index={0}>
               <p className={serviceHeaderStyles}>{title}</p>
               <div className={servicePanelContentStyles}>
                  <Markdown source={formattedText} escapeHtml={false} />
               </div>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
               <p className={serviceHeaderStyles} style={{ marginLeft: 20 }}>
                  SERVICE INQUIRY FORM
               </p>
               <p style={{ marginLeft: 20 }}>
                  Please fill out the following information to the best of your
                  knowledge at this time.
               </p>
               <p style={{ marginLeft: 20 }}>
                  Only fields with an asterisk are required for initial
                  discussions.
               </p>
               <p style={{ marginLeft: 20 }}>
                  For more information regarding services and capabilities see the <a href='../static-files/NSN%20Services%20Brochure.pdf' target='_new'>NSN Brochure</a>
               </p>
               <RequestServices />
            </TabPanel>
         </div>
      </Layout>
   )
}

export default WithLocation(OurServices)

export const NetworkIntServiceQuery = graphql`
   query NetworkIntServiceQuery {
      strapiService: allStrapiService(
         filter: {
            Title: { eq: "Communications and Navigation Network Services" }
         }
      ) {
         nodes {
            Title
            FormattedText {
               data {
                  FormattedText
               }
            }
         }
      }
      headerImage: file(relativePath: { eq: "headers/nis.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
