import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import SpotLight from '../components/spotLight'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Modal from '../components/modal'
import ContactUs from '../components/contactUs'
import sharedStyles from '../styles/global'
import ImageOverlay from '../components/imageOverlay'
import { isIE } from '../services/utilityService'
import Markdown from 'react-markdown/with-html'
function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box p={3}>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   feature: {
      display: '-ms-grid',
      display: 'grid',
      '& h3': {
         textAlign: 'center',
         paddingTop: '20px',
         paddingBottom: '25px',
      },
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr',
         msGridColumns: '1fr',
         textJustify: 'center',
      },
      [theme.breakpoints.up('sm')]: {
         gridTemplateColumns: '1fr 1fr',
         msGridColumns: '1fr 1fr',
         '& h3': {
            textAlign: 'left',
         },
      },
      marginTop: '25px',
      marginBottom: '25px',
   },
   featureImage: {
      msGridColumn: 1,
      alignSelf: 'center',
      justifySelf: 'center',
      msGridColumnAlign: 'center',
      msGridRowAlign: 'center',
   },
   mainHeader: {
      margin: theme.spacing(0, 0, 2, 0),
   },
   featureSection: {
      margin: theme.spacing(7, 0, 7, 0),
      //display: 'flex',
      flexDirection: 'column',
   },
   featureTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      msGridColumn: 2,
      '& h2': {
         margin: theme.spacing(0, 0, 2, 0),
      },
      [theme.breakpoints.down('sm')]: {
         '& h2': {
            margin: theme.spacing(3, 0, 2, 0),
            textAlign: 'center',
         },
      },
   },
   expertiseSection: {
      padding: theme.spacing(5, 0, 0, 0),
      '& h1': {
         margin: theme.spacing(0, 0, 1, 0),
      },
   },
   expertiseTabContent: {
      margin: theme.spacing(3, 0),
      display: '-ms-grid',
      display: 'grid',
      gridTemplateRows: '1fr max-content',
      msGridRows: '1fr max-content',
      gridGap: theme.spacing(2),
      // for repeating alternating rows
      // when in mobile 1 column layout
      gridTemplateAutoRows: '1fr max-content',
      [theme.breakpoints.down('xs')]: {
         gridGap: theme.spacing(3),
      },
   },
   expertise2ColGrid: {
      gridTemplateColumns: '1fr 1fr',
      msGridColumns: '1fr 1fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
      },
   },
   expertise3ColGrid: {
      gridTemplateColumns: '1fr 1fr 1fr',
      msGridColumns: '1fr 1fr 1fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
      },
   },
   expertiseIconDiv: {
      height: '190px',
      alignSelf: 'center',
      justifySelf: 'center',
      textAlign: 'center',
      msGridColumnAlign: 'center',
      msGridRowAlign: 'center',
      margin: theme.spacing(0, 0, 1, 0),
   },
   expertiseIcon: {
      alignSelf: 'center',
      justifySelf: 'center',
      height: '190px',
      margin: theme.spacing(0, 0, 1, 0),
      [theme.breakpoints.down('xs')]: {
         width: '100%',
      },
   },
   quoteSection: {
      padding: theme.spacing(8, 0, 12, 0),
      textAlign: 'center',
      fontWeight: 'bold',
   },
   quoteIcon: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(5),
      height: theme.spacing(8),
   },
   subTitle: {
      textAlign: 'right',
      fontWeight: 'normal',
      textJustify: 'auto',
   },
   contactSection: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: 'white',
   },
   contactUsSpacing: {
      padding: theme.spacing(8, 0, 8, 0),
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'space-between',
      '& *': {
         margin: theme.spacing(2, 0),
      },
   },
   contactButton: {
      textAlign: 'center',
      margin: 'auto',
      '& button': {
         padding: theme.spacing(1.3, 6),
         border: '1px solid white',
         borderRadius: '8px',
         color: 'white',
         backgroundColor: 'transparent',
         cursor: 'pointer',
      },
   },
   ieGridGap: {
      marginRight: theme.spacing(2),
   },
}))

const IndexPage = ({ data }) => {
   const { contactImage } = data
   const pageContent = data.allHomePageJson.nodes[0]
   const theme = useTheme()
   const css = useStyles(theme)
   const [activeTab, setActiveTab] = React.useState(0)
   const icons = data.allFile.nodes.reduce(function(map, icon) {
      if (icon.extension === 'svg') {
         map[icon.name] = icon.publicURL
      }
      return map
   }, {})
   const images = data.allFile.nodes.reduce(function(map, image) {
      if (image.extension != 'svg') {
         map[image.name] = image
      }
      return map
   }, {})

   const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
   const expertiseTabStyles = pageContent.expertise.tabs.map(tab => {
      const tabStyles = [css.expertiseTabContent]
      if (tab.columns.length === 2) {
         tabStyles.push(css.expertise2ColGrid)
      }
      if (tab.columns.length === 3) {
         tabStyles.push(css.expertise3ColGrid)
      }
      return tabStyles
   })

   let highlights = data.allStrapiHighlight.nodes
   let visibleHighlights = highlights.slice(0, 5)

   const layoutExpertiseGridItems = (
      columns,
      images,
      css,
      isSmallScreen,
      tabIndex
   ) => {
      const elementsInOrder = []

      //-- default to IE Grid gap to help with server rendering
      const imageCss = {
         overlay: { background: '' },
         image: { height: '200px', marginRight: theme.spacing(2) },
      }
      let gridGap = [css.ieGridGap]
      if (typeof window !== 'undefined') {
         if (isIE() === false) {
            gridGap = []
            imageCss.image = { height: '200px' }
         }
      }
      columns.forEach((column, colIdx) => {
         elementsInOrder.push(
            <div
               key={`image-${tabIndex}-${colIdx}`}
               className={[css.expertiseIconDiv, gridGap].join(' ')}
               style={{
                  msGridRow: 1,
                  msGridColumn: colIdx + 1,
                  width: '100%',
               }}
            >
               <ImageOverlay
                  image={images[column.paragraphImage]}
                  css={imageCss}
               />
            </div>
         )
         // if small screen push text immediately
         // after image to alternate down one column
         if (isSmallScreen) {
            elementsInOrder.push(
               <div
                  style={{ msGridColumn: colIdx + 1 }}
                  key={`text-${tabIndex}-${colIdx}`}
               >
                  <p className={[css.pt20].join(' ')}>
                     <Markdown source={column.text} escapeHtml={false} key="columnText"/>
                  </p>
               </div>
            )
         }
      })
      // if not small screen push all text elements
      // after all image elements to create two rows
      if (!isSmallScreen) {
         columns.forEach((column, colIdx) => {
            elementsInOrder.push(
               <div
                  className={[gridGap].join(' ')}
                  style={{ msGridRow: 2, msGridColumn: colIdx + 1 }}
                  key={`text-${tabIndex}-${colIdx}`}
               >
                  <p className={[css.pt20].join(' ')}><Markdown source={column.text} escapeHtml={false} key="columnText"/></p>
               </div>
            )
         })
      }
      return elementsInOrder
   }

   function handleChange(event, newValue) {
      setActiveTab(newValue)
   }

   // this is the code for the popup
   const [open, setOpen] = React.useState(false)
   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClose = () => {
      setOpen(false)
   }
   // end of code for popup

   return (
      <Layout>
         <SEO title="Home ESC" />
         <Helmet />

         <SpotLight data={visibleHighlights} />
         <br />
         <section>
            <div className={css.content}>
               <h1
                  className={[
                     css.pt65,
                     css.sansProBold,
                     css.uppercase,
                     css.center,
                     css.mainHeader,
                  ].join(' ')}
               >
                  {pageContent.main.header}
               </h1>
               <p className={[css.pt22].join(' ')}>
               <Markdown source={pageContent.main.body} escapeHtml={false} key="mainMrk"/>
               </p>

               <div className={css.featureSection}>
                  {pageContent.main.features.map((feature, featureIndex) => {
                     const gridRow = featureIndex + 1
                     return (
                        <div
                           className={css.feature}
                           key={`feature_${featureIndex}`}
                           style={{ msGridRow: gridRow }}
                        >
                           <div
                              className={css.featureImage}
                              style={{
                                 msGridRow: 1,
                                 msGridColumn: 1,
                                 width: '90%',
                              }}
                           >
                              <ImageOverlay
                                 image={images[feature.paragraphImage]}
                                 css={{
                                    overlay: { background: '' },
                                    image: { height: '200px' },
                                 }}
                              />
                           </div>

                           <div
                              className={css.featureTextContainer}
                              style={{
                                 width: '100%',
                                 height: '100%',
                                 backgroundImage: `url(${
                                    icons[feature.backgroundIcon]
                                 })`,
                                 backgroundPosition: 'center',
                                 backgroundRepeat: 'no-repeat',
                              }}
                           >
                              <div
                                 style={{
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(255,255,255,.9)',
                                 }}
                              >
                                 <h2
                                    className={[
                                       css.pt40,
                                       css.sansProSemiBold,
                                       css.uppercase,
                                    ].join(' ')}
                                 >
                                    {feature.header}
                                 </h2>
                                 <p className={[css.pt20].join(' ')}>
                                    {/* {feature.body} */}
                                    <Markdown source={feature.body} escapeHtml={false} key={`featureMrk_${featureIndex}`}/>
                                 </p>
                              </div>
                           </div>
                        </div>
                     )
                  })}
               </div>
            </div>
         </section>
         <section className={[css.fadedBlueBg, css.expertiseSection].join(' ')}>
            <div className={css.content}>
               <h1
                  className={[css.pt40, css.sansProBold, css.uppercase].join(
                     ' '
                  )}
               >
                  {pageContent.expertise.header}
               </h1>
               <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
               >
                  {pageContent.expertise.tabs.map(
                     (tab, expertiseHeaderIndex) => {
                        const tabStyles = [css.pt22, css.sansProSemiBold]
                        if (expertiseHeaderIndex !== activeTab) {
                           tabStyles.push(css.nonActiveTab)
                        }
                        const tabcss = tabStyles.join(' ')
                        return (
                           <Tab
                              className={tabcss}
                              label={tab.header}
                              {...a11yProps(expertiseHeaderIndex)}
                              key={`ExpertiseTabHeader_${expertiseHeaderIndex}`}
                           />
                        )
                     }
                  )}
               </Tabs>

               {pageContent.expertise.tabs.map((tab, expertiseTabIndex) => (
                  <TabPanel
                     value={activeTab}
                     index={expertiseTabIndex}
                     key={`ExpertiseTab_${expertiseTabIndex}`}
                  >
                     <p className={[css.pt22].join(' ')}>
                        <Markdown source={tab.text} escapeHtml={false} />
                     </p>
                     <div
                        className={expertiseTabStyles[expertiseTabIndex].join(
                           ' '
                        )}
                     >
                        {layoutExpertiseGridItems(
                           tab.columns,
                           images,
                           css,
                           isSmallScreen,
                           expertiseTabIndex
                        )}
                     </div>
                  </TabPanel>
               ))}
            </div>
         </section>
         <section className={css.quoteSection}>
            <div className={css.content}>
               <img
                  src={icons['testimonial']}
                  alt="quote icon"
                  className={css.quoteIcon}
               />
               <span
                  className={[css.pt40, css.sansPro, css.uppercase].join(' ')}
               >
                  {/* {pageContent.pulledQuote.text} */}
                  <Markdown source={pageContent.pulledQuote.text} escapeHtml={false} />
               </span>
            </div>
         </section>

         <section
            className={[css.contactSection].join(' ')}
            style={{
               backgroundImage: `url(${contactImage.childImageSharp.fluid.src})`,
            }}
         >
            <div className={[css.imageGradient].join(' ')}>
               <div className={[css.content, css.contactUsSpacing].join(' ')}>
                  <h3
                     className={[
                        css.pt40,
                        css.sansProBold,
                        css.center,
                        css.uppercase,
                     ].join(' ')}
                  >
                     Contact Us
                  </h3>
                  <p className={[css.pt22, css.center].join(' ')}>
                     {pageContent.contactUs.text}
                  </p>
                  <div className={css.contactButton}>
                     <button
                        className={[css.sansPro, css.pt22].join(' ')}
                        onClick={handleClickOpen}
                     >
                        {pageContent.contactUs.buttonText}
                     </button>
                  </div>
               </div>
            </div>
         </section>
         <Modal title={'Contact Us'} isOpen={open} handleClose={handleClose}>
            <ContactUs></ContactUs>
         </Modal>
      </Layout>
   )
}

export default IndexPage

export const pageQuery = graphql`
   query IndexQuery {
      contactImage: file(relativePath: { eq: "Image.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 1200) {
               ...GatsbyImageSharpFluid
            }
         }
      }

      allStrapiHighlight(sort: { fields: Order }) {
         nodes {
            Alt
            Blurb
            Title
            Link
            Order
            Gradient
            Image {
               localFile {
                  childImageSharp {
                     fluid(maxHeight: 960) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
            tags {
               Name
               id
            }
         }
      }

      allFile(filter: { dir: { glob: "**/landing" } }) {
         nodes {
            name
            publicURL
            extension
            childImageSharp {
               fluid {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }

      allHomePageJson {
         nodes {
            id
            pulledQuote {
               byline
               author
               text
            }
            contactUs {
               text
               buttonText
            }
            expertise {
               header
               tabs {
                  header
                  text
                  columns {
                     text
                     paragraphImage
                     alt
                  }
               }
            }
            main {
               body
               header
               features {
                  body
                  header
                  backgroundIcon
                  paragraphImage
               }
            }
         }
      }
   }
`
