import { Button, TextField } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#1D3787',// font color of label       
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#1D3787',//default border color no Focus
            },
            '&:hover fieldset': {// border color on mouseover
                borderColor: '#1D3787',
                borderWidth: 2,
            },
            '&.Mui-focused fieldset': {//border color on Focus
                // borderColor: 'red',
            },
        },
    },
})(TextField);

export default CssTextField