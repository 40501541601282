import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   catptionCredit: {
      margin: theme.spacing(1, 0, 0, 0),
      lineHeight: '1.3',
   },
}))

const Caption = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { text, credit } = props

   return (
      <div className={css.catptionCredit}>
         {text ? (
            <span className={[css.openSans, css.pt18].join(' ')}>{text}</span>
         ) : null}
         {text && credit ? <span> </span> : null}
         {credit ? (
            <span
               className={[css.openSansBold, css.pt18, css.italic].join(' ')}
            >
               Credit: {credit}
            </span>
         ) : null}
      </div>
   )
}

export default Caption
