import React, { useEffect } from 'react'
import { IconButton, Dialog } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'

const styles = theme => ({
   ...sharedStyles(theme),
   root: {
      margin: 0,
      padding: theme.spacing(1, 1, 1, 2),
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing(1),
      alignItems: 'center',
   },
   closeButton: {
      color: theme.palette.grey[500],
      justifySelf: 'end',
   },
   title: {
      whiteSpace: 'nowrap',
      justifySelf: 'start',
   },
})

const DialogTitle = withStyles(styles)(props => {
   const { children, classes, onClose } = props
   return (
      <MuiDialogTitle disableTypography className={classes.root}>
         <Typography variant="h6">{children}</Typography>
         {onClose ? (
            <IconButton
               aria-label="close"
               className={classes.closeButton}
               onClick={onClose}
            >
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
   )
})

const DialogContent = withStyles(theme => ({
   root: {
      padding: theme.spacing(2),
   },
}))(MuiDialogContent)

const Modal = withStyles(styles)(props => {
   const { title, tags, isOpen, handleClose, children, classes } = props

   return (
      <Dialog
         onClose={handleClose}
         aria-labelledby="customized-dialog-title"
         open={isOpen}
         maxWidth="lg"
      >
         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div className={classes.title}>{title}</div>
         </DialogTitle>
         <DialogContent dividers>{children}</DialogContent>
      </Dialog>
   )
})

export default Modal
