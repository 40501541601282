import React from 'react'
import { graphql, navigate, Link } from 'gatsby'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
   Typography,
   Button,
   Grid,
   Paper,
   Card,
   CardActions,
   CardContent,
   Container,
   useMediaQuery,
} from '@material-ui/core'
import sharedStyles from '../styles/global'
import Layout from '../components/layout'
import ContactUs from '../components/contactUs'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'
import ImageOverlay from '../components/imageOverlay'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   servicesPageHeader: {
      margin: '3rem 0rem 0.3rem 0rem',
   },
   title: {
      fontSize: 14,
   },
   galleryTextWrapper: {
      textAlign: 'center',
   },
   galleryText: {
      color: 'white',
      display: 'inline-table',
      verticalAlign: 'middle',
      lineHeight: 1.25,
   },
   colorBox: project => ({
      width: '10px',
      height: '10px',
      display: 'inline-block',
      backgroundColor: project.color,
      left: '5px',
      top: '5px',
   }),
}))

const OurServices = ({ data, location }) => {
   const theme = useTheme()
   const css = useStyles(theme)
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
   const serviceHeaderStyles = [
      css.uppercase,
      css.sansProSemiBold,
      css.serviceHeader,
      isLargeScreen ? css.pt40 : css.pt30,
   ].join(' ')
   const serviceSubHeaderStyles = [
      css.bold,
      isLargeScreen ? css.pt26 : css.pt20,
   ].join(' ')
   const cssSettings = {
      crop: false,
      image: { height: '200px' },
      container: {
         height: '200px',
         maxHeight: '200px',
         borderRadius: '5px',
         '&:hover': {
            transform: 'scale(1.03)',
            '& > div': {
               background: 'rgba(29,55,135,0.6)',
            },
         },
      },
      //TODO: adjust padding based on break points to prevent word wrap
      text: {
         paddingRight: '10px',
         paddingLeft: '10px',
         transform: 'translateX(-50%)',
         top: '35%',
      },
   }
   return (
      <Layout>
         <SEO
            title="Services"
            description="Services - ESC Public Site"
            keywords="Services - ESC Public Site (ESC)"
         />
         <Helmet>
            <meta charSet="utf-8" />
         </Helmet>
         <ImageHeader
            title={`Obtain Services`}
            image={data.headerImage}
         ></ImageHeader>
         <div id="content" className={css.content} style={{ marginTop: 20 }}>
            <Container component="main" maxWidth="md">
               <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} component={Card}>
                     <Link to={`/networkIntServices`}>
                        <ImageOverlay
                           image={data.nisImage}
                           alt="Network Integration Services"
                           css={cssSettings}
                        ></ImageOverlay>
                     </Link>
                     <CardContent>
                        <h3>COMMUNICATIONS & NAVIGATION NETWORK SERVICES</h3>
                        <Typography size="small" style={{ marginTop: 10 }}>
                           The Near Space Network synthesizes communications
                           services for missions seeking access to space data.{' '}
                           <Link to={`/networkIntServices`}>Learn more.</Link>
                        </Typography>
                     </CardContent>
                  </Grid>
                  <Grid item xs={12} sm={6} component={Card}>
                     <Link to={`/otherServices`}>
                        <ImageOverlay
                           image={data.mdasImage}
                           alt="Other Services"
                           css={cssSettings}
                        ></ImageOverlay>
                     </Link>
                     <CardContent>
                        <h3>OTHER SERVICES</h3>
                        <Typography size="small" style={{ marginTop: 10 }}>
                           Various mission design and analysis groups support
                           the development of a mission’s communications and
                           navigation architecture.{' '}
                           <Link to={`/otherServices`}>Learn more.</Link>
                        </Typography>
                     </CardContent>
                  </Grid>
               </Grid>
            </Container>
            {/* <Grid item xs={12} sm={12} style={{ marginTop: 40 }}>
               <p className={serviceSubHeaderStyles} style={{ margin: 20 }}>Contact Us</p>
               <ContactUs />
            </Grid> */}
         </div>
      </Layout>
   )
}

export default WithLocation(OurServices)

export const ServiceQuery = graphql`
   query ServiceQuery {
      headerImage: file(relativePath: { eq: "headers/ourservices.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
      nisImage: file(relativePath: { eq: "headers/nis.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
      mdasImage: file(relativePath: { eq: "headers/mdas.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
