import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   referenceType: {
      margin: theme.spacing(1, 0, 0, 0),
      lineHeight: '1.3',
   },
}))

const ReferenceType = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { text, refType } = props

   return (
      <div className={css.referenceType}>
         {text ? (
            <span className={[css.openSans, css.pt18].join(' ')}>{text}</span>
         ) : null}
         {text && refType ? <span> </span> : null}
         {refType ? (
            <span
               className={[css.openSansBold, css.pt18, css.italic].join(' ')}
            >
               {refType}
            </span>
         ) : null}
      </div>
   )
}


export default ReferenceType
