import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../../styles/global'
import SubHeading from '../subHeading'
import Markdown from 'react-markdown/with-html'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
}))

const TextBlock = ({ subHeading, text }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   return (
      <>
         {subHeading && (
            <h2>
               <SubHeading text={subHeading}></SubHeading>
            </h2>
         )}
         {text && (
            <div className={[css.pt20, css.openSans, css.regular].join(' ')}>
               <Markdown
                  source={text
                     .replace(/(?:\r\n|\r|\n)/g, '<br />')
                     .replace(/(?:>>)/g, '>&nbsp;&nbsp;')}
                  escapeHtml={false}
               />
            </div>
         )}
      </>
   )
}

export default TextBlock
