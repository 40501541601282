import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
   list: {
      listStyleType: 'none',
      display: 'inline-block',
      paddingInlineStart: '0px',
      whiteSpace: 'nowrap',
      marginRight: '1rem',
   },
   pagingItem: {
      listStyleType: 'none',
      display: 'inline-block',
      padding: '10px',
      border: 'none',
      '& active': {
         border: '1px solid red',
      },
   },
   selected: {
      backgroundColor: 'white',
   },
   button: {
      cursor: 'pointer',
      background: 'transparent',
      border: 'none',
   },
   pagingDot: {
      display: 'inline-block',
      border: '1.5px solid white',
      width: '12px',
      height: '12px',
      backgroundColor: 'transparent',
      borderRadius: '50%',
   },
}))

function getDotIndexes(slideCount, slidesToScroll, slidesToShow, cellAlign) {
   const dotIndexes = []
   let lastDotIndex = slideCount - slidesToShow

   switch (cellAlign) {
      case 'center':
      case 'right':
         lastDotIndex += slidesToShow - 1
         break
   }
   if (lastDotIndex < 0) {
      return [0]
   }

   for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
      dotIndexes.push(i)
   }
   dotIndexes.push(lastDotIndex)
   return dotIndexes
}

function getRadioStyles(active) {
   return {
      backgroundColor: active ? 'white' : 'transparent',
   }
}

export default function PagingDots(props) {
   const indexes = getDotIndexes(
      props.slideCount,
      props.slidesToScroll,
      props.slidesToShow,
      props.cellAlign
   )

   const theme = useTheme()
   const classes = useStyles(theme)

   return (
      <ul className={classes.list}>
         {indexes.map(index => {
            const isSelected = props.currentSlide === index
            return (
               <li className={classes.pagingItem} key={index}>
                  <button
                     type="button"
                     className={classes.button}
                     onClick={props.goToSlide.bind(null, index)}
                     aria-label={`slide ${index + 1} bullet`}
                  >
                     <span
                        className={[
                           classes.pagingDot,
                           isSelected ? classes.selected : '',
                        ].join(' ')}
                        style={getRadioStyles(isSelected)}
                     />
                  </button>
               </li>
            )
         })}
      </ul>
   )
}
