import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../../styles/global'
import Markdown from 'react-markdown/with-html'
import ImageOverlayWithCaption from '../imageOverlayWithCaption'
import TextBlock from './textBlock'
import Img from 'gatsby-image'
const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   float: {
      float: 'right',
   },
}))

const FloatingImage = ({ css, article, direction }) => {
   const theme = useTheme()
   const localCss = useStyles(theme)
   const showCaption = false

   const FloatingImageSettings = {
      crop: true,
      figure: {
         display: 'inline-block',
         float: direction,
         width: '35%',
         marginBottom: '20px',
      },
      container: {
         height: '100%',
         crop: true,
         width: '100%',
         marginRight: '1.5rem',
      },
      image: {
         width: '100%',
         maxWidth: '100%',
         height: '100%',
      },
      overlay: { background: '' },
   }
   const cssSettings = {
      crop: true,
      container: {
         maxHeight: '400px',
      },
      image: {},
      overlay: { background: '' },
   }

   if (direction === 'right') {
      FloatingImageSettings.figure.marginLeft = '1.5rem'
   } else {
      FloatingImageSettings.figure.marginRight = '1.5rem'
   }

   return (
      <>
         {article.image1 && (
            <ImageOverlayWithCaption
               image={article.image1.File.localFile}
               alt={article.image1.Alt}
               css={FloatingImageSettings}
               showCaption={showCaption}
               caption={article.image1.Caption}
               credit={article.image1.Credit}
            ></ImageOverlayWithCaption>
         )}
         <TextBlock
            subHeading={article.SubHeading1}
            text={article.Text1.data.Text1}
         />

         <TextBlock
            subHeading={article.SubHeading2}
            text={article.Text2.data.Text2}
         />
         {article.image2 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image2.File.localFile}
                  alt={article.image2.Alt}
                  css={FloatingImageSettings}
                  showCaption={showCaption}
                  caption={article.image2.Caption}
                  credit={article.image2.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading3}
            text={article.Text3.data.Text3}
         />
         {article.image3 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image3.File.localFile}
                  alt={article.image3.Alt}
                  css={FloatingImageSettings}
                  showCaption={showCaption}
                  caption={article.image3.Caption}
                  credit={article.image3.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading4}
            text={article.Text4.data.Text4}
         />
         {article.image4 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image4.File.localFile}
                  alt={article.image4.Alt}
                  css={FloatingImageSettings}
                  showCaption={showCaption}
                  caption={article.image4.Caption}
                  credit={article.image4.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading5}
            text={article.Text5.data.Text5}
         />
      </>
   )
}

export default FloatingImage
