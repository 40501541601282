import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
}))

const Contact = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { info } = props
   const mailto = `mailto:${info.email}`

   return (
      <div>
         {info.facility && <div>{info.facility}</div>}
         {info.name && <div>{info.name}</div>}
         {info.title && <div className={css.openSansBold}>{info.title}</div>}
         {info.email && (
            <div>
               <a href={mailto}>{info.email}</a>
            </div>
         )}
         {info.phone && <div>Phone: {info.phone}</div>}
      </div>
   )
}

export default Contact
