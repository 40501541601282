import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   container: {
      paddingTop: '48px',
      paddingBottom: '48px',
      minHeight: 200,
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      color: 'white',
   },
   quote: {
      width: '80%',
      textAlign: 'justify',
   },
   attribution: {
      textAlign: 'right',
      width: '75%',
      fontStyle: 'italic',
      paddingTop: '3vh',
   },
}))

const PullQuote = props => {
   const classes = useStyles()
   const data = useStaticQuery(graphql`
      query {
         file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
            id
            name
            publicURL
            childImageSharp {
               fluid(maxHeight: 960) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `)

   const image =
   props.backgroundImage && props.backgroundImage.file && props.backgroundImage.file.childImageSharp
         ? props.backgroundImage.file.childImageSharp.fluid
         : data.file.childImageSharp.fluid
   return (
      <BackgroundImage
         Tag="div"
         fluid={[
            `linear-gradient(to bottom, rgba(62, 99, 211, 0.5), rgba(0, 0, 0, 0.5))`,
            image,
         ]}
         className={classes.container}
      >
         <div className={classes.container}>
            <p
               className={[
                  classes.quote,
                  classes.sansPro,
                  classes.pt30,
                  classes.bold,
               ].join(' ')}
            >
               &ldquo;
               {props.quote}
               &rdquo;
            </p>
            <div className={classes.attribution}>
               <p
                  className={[
                     classes.source,
                     classes.sansPro,
                     classes.pt22,
                     classes.opacity60,
                  ].join(' ')}
               >
                  &mdash;&nbsp;{props.source}
               </p>
               {props.sourceSubtitle && (
                  <p className={[classes.pt16, classes.opacity60].join(' ')}>
                     {props.sourceSubtitle}
                  </p>
               )}
            </div>
         </div>
      </BackgroundImage>
   )
}

PullQuote.propTypes = {
   quote: PropTypes.string,
   source: PropTypes.string,
   sourceSubtitle: PropTypes.string,
   backgroundImage: PropTypes.object,
}

export default PullQuote
