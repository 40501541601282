import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
   AccessTime,
   KeyboardArrowLeft,
   KeyboardArrowRight,
} from '@material-ui/icons'
import Carousel from 'nuka-carousel'
import PropTypes from 'prop-types'
import sharedStyles from '../styles/global'
import ImageOverlayArticles from './imageOverlayArticles'
import _ from 'lodash'
import { findImage } from '../services/articleService'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   carouselContainer: {
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: '25px 1fr 1fr 1fr 1fr 20px',
      [theme.breakpoints.down('md')]: {
         gridTemplateColumns: '25px 1fr 1fr 1fr 20px',
      },
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '25px 1fr 1fr 20px',
      },
   },
   controlStyling: {
      fontSize: '2.5rem',
      marginTop: '25%',
   },
   tagPosition: {
      marginLeft: '5px',
      marginTop: '5px',
   },
   captionSpacing: {
      marginTop: '10px',
   },
   titleSpacing: {
      paddingTop: '0.6rem',
      paddingBottom: '0.2rem',
      lineHeight: 1.2,
   },
}))

//-- helps calculate the window size
let defaultWidth
let defaultHeight
if (typeof window !== `undefined`) {
   defaultHeight = window.innerHeight
   defaultWidth = window.innerWidth
}
const useWindowSize = () => {
   const [dimensions, setDimensions] = useState({
      windowHeight: defaultHeight,
      windowWidth: defaultWidth,
   })
   useEffect(() => {
      const handler = () =>
         setDimensions({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
         })
      window.addEventListener(`resize`, handler)
      return () => window.removeEventListener(`resize`, handler)
   }, [])
   return dimensions
}

const getNumPictures = theme => {
   let numPictures = 4
   const viewWidth = useWindowSize(1200).windowWidth
   if (viewWidth < theme.breakpoints.values.lg) {
      numPictures = 3
   }
   if (viewWidth < theme.breakpoints.values.sm) {
      numPictures = 2
   }
   return numPictures
}

const getRelatedArticles = (articles, tags) => {
   const related = articles.filter(article => {
      let found = false
      const matches = _.intersectionBy(article.tags, tags, 'Name')
      if (matches && matches.length > 0) found = true
      return found
   })
   const flt = _.orderBy(related, ['publish'], ['desc'])
   return flt.sort((a, b) => new Date(b.Publish) - new Date(a.Publish))
}

const RelatedArticles = props => {
   const { articles, tags, images } = props
   const theme = useTheme()
   const classes = useStyles(theme)
   const relatedArticles = getRelatedArticles(articles, tags).map(node => {
      if (node.Header) {
         node.Header = findImage(node.Header, images, true)
      } else {
         //handle the case where there is no Header image
         node.Header = { File: null }
      }
      return node
   })
   const numPictures = getNumPictures(theme)

   const tagStyling = [
      classes.sansPro,
      classes.pt16,
      classes.bold,
      classes.opacity60,
      classes.uppercase,
      classes.tagPosition,
   ].join(' ')
   const overlayCss = {
      container: {
         '&:hover': {
            '& > div': {
               background: 'rgba(29,55,135,0.7)',
            },
            '& > span': {
               display: 'block',
            },
         },
      },
      overlay: {
         background: '',
      },
      image: { height: '120px' },
      text: {
         top: '20%',
         lineHeight: 1.25,
         display: 'none',
         paddingLeft: '10px',
      },
   }
   if (relatedArticles.length === 0) return <> </>
   else
      return (
         <Carousel
            renderCenterLeftControls={({ previousSlide }) => (
               <KeyboardArrowLeft
                  onClick={previousSlide}
                  className={classes.controlStyling}
               />
            )}
            renderCenterRightControls={({ nextSlide }) => (
               <KeyboardArrowRight
                  onClick={nextSlide}
                  className={classes.controlStyling}
               />
            )}
            renderBottomCenterControls={props => ''}
         >
            {_.chunk(relatedArticles, numPictures).map((chunk, i) => (
               <div className={classes.carouselContainer} key={`chunk_${i}`}>
                  <span></span>
                  {chunk.map((article, index) => (
                     <Link
                        to={`/news/${article.Slug}`}
                        key={`relatedArticle_${index}`}
                     >
                        <div>
                           {article.Header ? (
                              <ImageOverlayArticles
                                 image={article.Header.File}
                                 css={overlayCss}
                                 showCaption={false}
                              >
                                 <div className={tagStyling}>
                                    {article.tags
                                       .map(tag => tag.Name)
                                       .join(', ')}
                                 </div>
                              </ImageOverlayArticles>
                           ) : (
                              <ImageOverlayArticles
                                 image={article.missingImage}
                                 css={overlayCss}
                                 showCaption={false}
                              >
                                 <div className={tagStyling}>
                                    {article.tags
                                       .map(tag => tag.Name)
                                       .join(', ')}
                                 </div>
                              </ImageOverlayArticles>
                           )}

                           <div>
                              <div
                                 className={[
                                    classes.sansProBold,
                                    classes.pt18,
                                    classes.titleSpacing,
                                 ].join(' ')}
                              >
                                 <span className={classes.pointer}>
                                    {article.Title}
                                 </span>
                              </div>
                              <div
                                 className={[
                                    classes.pt16,
                                    classes.openSans,
                                    classes.italic,
                                 ].join(' ')}
                              >
                                 <AccessTime style={{ fontSize: '12px' }} />
                                 &nbsp;
                                 {article.Publish}
                              </div>
                           </div>
                        </div>
                     </Link>
                  ))}
                  <span></span>
               </div>
            ))}
         </Carousel>
      )
}

RelatedArticles.propTypes = {
   id: PropTypes.arrayOf(PropTypes.object),
}

export default RelatedArticles
