import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'
import { Box, useMediaQuery } from '@material-ui/core'
import ImpactCard from './impactCard'

const useStyles = makeStyles(theme => ({
    ...sharedStyles(theme),
    gridContainer: {
        display: 'grid',
        gridTemplateRows: '300px',
        gridAutoRows: '300px',
        gridTemplateColumns: 'repeat(4, minmax(220px, 1fr))',
        msGridRows: '300px (0.7rem 300px)[3]',
        msGridColumns: 'minmax(220px, 1fr) (0.7rem minmax(220px, 1fr))[3]',
        [theme.breakpoints.down('950')]: {
            gridTemplateColumns: 'repeat(3, minmax(210px, 1fr))',
            msGridColumns: 'minmax(210px, 1fr) (0.7rem minmax(210px, 1fr))[2]',
            msGridRows: '300px (0.7rem 300px)[4]',
        },
        [theme.breakpoints.down('700')]: {
            gridTemplateColumns: 'repeat(2, minmax(220px, 1fr))',
            msGridColumns: 'minmax(220px, 1fr) 0.7rem minmax(220px, 1fr)',
            msGridRows: '300px (0.7rem 300px)[7]',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '250px',
            msGridColumns: '250px',
            msGridRows: '300px (0.7rem 300px)[14]',
        },
        gridGap: '0.7rem',
        justifyItems: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

const ImpactCardGrid = (props) => {

    const theme = useTheme()
    const classes = useStyles(theme)

    const { impactCards } = props

    let columns = 4
    let rows = 4
    if (useMediaQuery(theme.breakpoints.down('950'))) {
        columns = 3
        rows = 5
    }
    if (useMediaQuery(theme.breakpoints.down('700'))) {
        columns = 2
        rows = 8
    }
    if (useMediaQuery(theme.breakpoints.down('xs'))) {
        columns = 1
        rows = 15
    }

    function calculateMSGridPlacement(idx) {
        let col = ((idx % columns) * 2) + 1
        let row = ((Math.floor(idx/columns) % rows) * 2) + 1
        return {
            row,
            col
        }
    }

    return (
        <Box px={10} py={6} className={[classes.gridContainer, classes.fadedBlueBg].join(' ')}>
            {impactCards.map((impact, idx) => (
                <ImpactCard impact={impact} key={idx} msGrid={calculateMSGridPlacement(idx)} />
            ))}
        </Box>
    )
}

export default ImpactCardGrid
