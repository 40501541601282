import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const HealthCheckPage = () => (
   <Layout>
      <SEO title="Health Check" />
      <h1>Will add content here later</h1>
   </Layout>
)

export default HealthCheckPage
