import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../../styles/global'
import ImageOverlayWithCaption from '../imageOverlayWithCaption'
import ImageOverlay from '../imageOverlay'
import Caption from '../caption'
import TextBlock from './textBlock'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   sideBySideImages: {
      display: '-ms-grid',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      msGridColumns: '1fr 1fr',
      gridGap: '15px',
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr',
         msGridColumns: '1fr',
         marginLeft: '20px',
         marginRight: '20px',
      },
   },
   ieGridGap: {
      marginRight: '15px',
      marginBottom: '15px',
   },
}))

const SideBySide = ({ css, article }) => {
   const theme = useTheme()
   const localCss = useStyles(theme)
   const showCaption = false

   const sideBySideImageSettings = {
      crop: true,
      container: {
         maxHeight: '473px',
      },
      image: {
         height: '237px',
      },
      overlay: { background: '' },
   }
   const cssSettings = {
      crop: true,
      container: {
         maxHeight: '400px',
      },
      image: {},
      overlay: { background: '' },
   }

   return (
      <>
         <TextBlock
            subHeading={article.SubHeading1}
            text={article.Text1.data.Text1}
         />
         {article.image1 && article.image2 && (
            <div className={css.imageSpacing}>
               <div className={localCss.sideBySideImages}>
                  <div style={{ msGridColumn: 1 }}>
                     <ImageOverlay
                        image={article.image1.File}
                        alt={article.image1.Alt}
                        css={sideBySideImageSettings}
                        showCaption={showCaption}
                     ></ImageOverlay>
                  </div>
                  <div style={{ msGridColumn: 2 }}>
                     <ImageOverlay
                        image={article.image2.File}
                        alt={article.image2.Alt}
                        css={sideBySideImageSettings}
                        showCaption={showCaption}
                     ></ImageOverlay>
                  </div>
               </div>
               <div className={localCss.sideBySideImages}>
                  <Caption
                     text={`(First) ${article.image1.Caption}`}
                     credit={article.image1.Credit}
                  ></Caption>
                  <Caption
                     text={`(Second) ${article.image2.Caption}`}
                     credit={article.image2.Credit}
                  ></Caption>
               </div>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading2}
            text={article.Text2.data.Text2}
         />
         {article.image3 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image3.File}
                  alt={article.image3.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image3.Caption}
                  credit={article.image3.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading3}
            text={article.Text3.data.Text3}
         />
         {article.image4 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image4.File}
                  alt={article.image4.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image4.Caption}
                  credit={article.image4.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}
         <TextBlock
            subHeading={article.SubHeading4}
            text={article.Text4.data.Text4}
         />
         <TextBlock
            subHeading={article.SubHeading5}
            text={article.Text5.data.Text5}
         />
      </>
   )
}

export default SideBySide
