import React from 'react'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SubMenu from '../components/participateSubMenus'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import sharedStyles from '../styles/global'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   participateMainHeader: {
      margin: '3rem 0rem 0.3rem 0rem',
   },
   mainUL: {
      listStyleType: 'decimal',
      marginLeft: '20px',
      [theme.breakpoints.up('md')]: {
         marginLeft: '20px',
      },
   },
   subUL: {
      listStyleType: 'circle',
      marginLeft: '20px',
      [theme.breakpoints.up('md')]: {
         marginLeft: '40px',
      },
   },
}))

const ProjectPage = () => {
   const theme = useTheme()
   const classes = useStyles()
   const data = useStaticQuery(graphql`
      query {
         strapiProject(Acronym: { eq: "SIP" }) {
            facts {
               Alt
               Content
               Image {
                  localFile {
                     childImageSharp {
                        fluid(maxHeight: 300) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
         }
         projImage: file(relativePath: { eq: "projects/DSC_5032.JPG" }) {
            childImageSharp {
               fluid(maxHeight: 960) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         overviewjImage: file(relativePath: { eq: "projects/Interns-86.jpg" }) {
            childImageSharp {
               fluid(maxHeight: 960) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `)

   return (
      <Layout>
         <SEO title="Participate" />
         <Helmet>
            <meta charSet="utf-8" />
         </Helmet>
         <div>
            <div className={classes.content}>
               <h1
                  className={[
                     classes.participateMainHeader,
                     classes.uppercase,
                  ].join(' ')}
               >
                  PARTICIPATE
               </h1>
               <SubMenu selected={0} />
            </div>

            <div className={classes.content} style={{ marginBottom: '50px' }}>
               <div style={{ marginBottom: '50px' }}>
                  <p>
                     The Exploration and Space Communications (ESC) projects
                     division administers the Space Communications and
                     Navigation (SCaN) Internship Project (SIP) at NASA’s
                     Goddard Space Flight Center in Greenbelt, Maryland. SIP
                     prepares young professionals with the skills, experience
                     and connections they need to thrive in the workplace and
                     further NASA’s mission. Each intern is paired with a
                     mentor, an expert in their field, who can guide them to
                     success. Interns work with their mentors to complete a
                     project of measurable importance to the agency.
                  </p>
                  <br />
                  {data.overviewjImage.childImageSharp && (
                     <figure>
                        <Img
                           fluid={data.overviewjImage.childImageSharp.fluid}
                           alt="Interns at Wallops Flight Facility in Wallops, VA. "
                        ></Img>
                        <figcaption className={classes.pt16}>
                           <p>
                              Interns at Wallops Flight Facility in Wallops, VA.
                           </p>
                        </figcaption>
                     </figure>
                  )}
               </div>
               <div style={{ marginBottom: '50px' }}>
                  <h2>The Intern Impact </h2>
                  <p>
                     SIP interns contribute to ESC in a variety of roles across
                     many diverse disciplines from cybersecurity to public
                     outreach. Intern projects further ESC’s mission, pursuing
                     bold new lines of inquiry and lending fresh eyes to the
                     communications challenges of today. Past interns have
                     enhanced network capabilities, patented unique
                     communications technologies and improved the efficiency of
                     agency operations.
                     <br />
                     <br />
                     Many SIP interns go on to work for NASA, furthering the
                     pipeline of new talent to the agency by becoming mentors
                     themselves.
                  </p>
                  <br />
                  <h2>Professional Development</h2>
                  <p>
                     In return for their contributions, SCaN and ESC provide
                     their interns with unique opportunities to grow their
                     skills and interact with aerospace professionals. Interns
                     are encouraged to incorporate creativity into their design
                     processes. They are inundated with challenging ideas and
                     new perspectives. They network with current and future
                     leaders, building the connections that will define their
                     careers.
                     <br />
                     <br />
                     Interns leave the program with a better understanding of
                     the unique contributions they can make to NASA and the
                     world at large.
                  </p>
                  <br />
                  {data.projImage.childImageSharp && (
                     <figure>
                        <Img
                           fluid={data.projImage.childImageSharp.fluid}
                           alt="Interns attending a professional development workshop."
                        ></Img>
                        <figcaption className={classes.pt16}>
                           <p>
                              Interns attending a professional development
                              workshop.
                              <br />
                              <b>Credits: NASA/GSFC</b>
                           </p>
                        </figcaption>
                     </figure>
                  )}
               </div>
               <div style={{ marginBottom: '50px' }}>
                  <h2>Summer 2020 Positions</h2>
                  <p>
                     To apply to SCaN Internship Project opportunities:
                     <ul className={classes.mainUL}>
                        <li>
                           Make a profile on{' '}
                           <a href="intern.nasa.gov" target="_blank">
                              intern.nasa.gov
                           </a>
                        </li>
                        <li>
                           Under "Available Projects," filter for:
                           <ul className={classes.subUL}>
                              <li>Session: "Summer 2020"</li>
                              <li>Center: "Goddard Space Flight Center”</li>
                              <li>Keyword: “SIP”</li>
                           </ul>
                        </li>
                     </ul>
                     To learn more about SIP, follow this link.
                  </p>
               </div>
            </div>
         </div>
      </Layout>
   )
}

export default ProjectPage
