import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

const useStyles = makeStyles(theme => ({
   root: {
      width: '100%',
      marginTop: theme.spacing(3),
   },
   alertBox: {
      backgroundColor: '#FFF3CD',
      border: '2px solid #F0C436',
      fontSize: '13px',
      color: '#000',
      padding: '15px',
      display: 'flex',
      alignItems: ' center',
      justifyContent: 'center',
   },
   alert: {
      color: '#F0C436',
      float: 'left',
      margin: '10px',
   },
   underline: { textDecoration: 'underline' },
}))

export default function DescriptionAlerts() {
   const classes = useStyles()

   return (
      <div className={classes.root}>
         <div className={classes.alertBox}>
            <ReportProblemIcon className={classes.alert} />
            <span>
               <b>
                  <i>Disclaimer: </i>
               </b>
               &nbsp;This material is being kept online for historical purposes.
               The time and accuracy of this page may no longer coincide with
               updated information and may contain broken links. &nbsp;
               <Link
                  href="/news/Near-Space_Communications_Reimagined"
                  className={classes.underline}
               >
                  Visit this page
               </Link>
               &nbsp; for information about our current network and division
               structure.
            </span>
         </div>
      </div>
   )
}
