const sharedStyles = theme => ({
    content: {
        width: '80%',
        maxWidth: '1000px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },

    //-- Text Sizes
    pt80: {
        fontSize: '3rem',
    },
    pt70: {
        fontSize: '2.6rem',
    },
    pt65: {
        fontSize: '2.4rem',
    },
    pt60: {
        fontSize: '2.2rem',
    },
    pt50: {
        fontSize: '2.05rem',
    },
    pt45: {
        fontSize: '2rem',
    },
    pt40: {
        fontSize: '1.9rem',
    },
    pt35: {
        fontSize: '1.6rem',
    },
    pt30: {
        fontSize: '1.4rem',
    },
    pt26: {
        fontSize: '1.2rem',
    },
    pt22: {
        fontSize: '1.0rem',
    },
    pt20: {
        fontSize: '0.9rem',
    },
    pt18: {
        fontSize: '0.75rem',
    },
    pt16: {
        fontSize: '0.6rem',
    },
    pt14: {
        fontSize: '0.45rem',
    },

    //-- Text Transformations
    uppercase: {
        textTransform: 'uppercase',
    },
    capitalize: {
        textTransform: 'capitalize',
    },

    //-- Text weight
    lighter: {
        color: '#22409B'
    },
    regular: {
        fontWeight: 'normal'
    },
    semiBold: {
        fontWeight: 400,
    },
    bold: {
        fontWeight: 800
    },
    italic: {
        fontStyle: 'italic'
    },
    opacity60: {
        opacity: 0.6
    },
    opacity70: {
        opacity: 0.7
    },
    opacity80: {
        opacity: 0.8
    },
    opacity90: {
        opacity: 0.9
    },

    //-- fonts
    sansProBold: {
        fontFamily: 'Source Sans Pro Bold'
    },
    sansPro: {
        fontFamily: 'Source Sans Pro'
    },
    sansProSemiBold: {
        fontFamily: 'Source Sans Pro SemiBold',
    },
    openSans: {
        fontFamily: 'Open Sans'
    },
    openSansBold: {
        fontFamily: 'Open Sans Bold'
    },
    //-- layout
    center: {
        textAlign: 'center',
    },

    //-- background
    imageGradient: {
        background: 'linear-gradient(to bottom, rgba(29, 55, 135, 0.7), rgba(0, 0, 0, 0.7))'
    },
    fadedBlueBg: {
        backgroundColor: 'rgba(29, 55, 135, 0.07)', // #1D3787 (ESC Blue) with 7% opacity
    },

    //-- Mui Tab controls
    tabControl: {
        whiteSpace: 'nowrap',
        // override text-transform on .MuiTab-root
        textTransform: 'none',
    },
    nonActiveTab: {
        color: '#1D3787',
        opacity: '0.6'
    },
    submit: {
        fontFamily: 'source Sans Pro SemiBold',
        margin: theme.spacing(2, 0),
        width: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textFieldLabel: {
        backgroundColor: 'white',
        lineHeight:'1.1',
        display: 'inline-block',
         [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(4.6),
          },
    },
})

export default sharedStyles
