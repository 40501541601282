import React from 'react'
import Caption from './caption'
import ImageOverlay from './imageOverlay'

const ImageOverlayWithCaption = props => {
   const { image, alt, css, children, showCaption, caption, credit } = props

   return (
      <figure style={css.figure}>
         <ImageOverlay
            image={image}
            alt={alt}
            css={css}
            showCaption={showCaption}
         >
            {children}
         </ImageOverlay>
         <figcaption>
            <Caption text={caption} credit={credit} />
         </figcaption>
      </figure>
   )
}

export default ImageOverlayWithCaption
