import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   impactCard: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: '6px',
      transition: 'transform 0.1s linear',
      '&:hover': {
         transform: 'scale(1.03)',
         // TODO:
         // get the slide-up transition on the overlay
         // triggered from the parent container hover
         // (since you can't hover over something off-screen)
         // see this pen for an example:
         // https://codepen.io/dylancristy/pen/GRKeRvo
         //
         // '.textOverlay': {
         //     transform: 'translateY(0%)',
         // }
         //
         // TODO:
         // also add a small drop-shadow to
         // give the appearance of "elevation"
         // when the card is hovered/active
      },
   },
   imageContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
   },
   textOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '1.5rem 1.2rem',
      backgroundColor: 'rgba(62, 99, 211, 0.7)', // #3E63D3 (Lighter Blue) with 70% opacity
      color: 'white',
      fontFamily: 'Source Sans Pro',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      // TODO:
      // change the transition from opacity
      // to sliding up from bottom
      // transform: 'translateY(100%)',
      // transition: 'transform 0.1s ease',
      transition: 'opacity 0.1s linear',
      opacity: '0',
      '&:hover': {
         opacity: '1',
      },
   },
   impactTitle: {
      marginBottom: '0.7rem',
      fontWeight: '600',
   },
   impactText: {
      fontSize: '0.95rem',
   },
}))

const ImpactCard = props => {
   const theme = useTheme()
   const classes = useStyles(theme)

   const defaultImg = useStaticQuery(graphql`
      query {
         image: file(
            relativePath: { eq: "impacts/hubble_scientificexploration.png" }
         ) {
            childImageSharp {
               fixed(cropFocus: CENTER, fit: COVER, width: 310) {
                  ...GatsbyImageSharpFixed
               }
            }
         }
      }
   `)

   const { impact, msGrid } = props
   const showImage = impact.image || defaultImg.image.childImageSharp.fixed

   return (
      <div
         className={classes.impactCard}
         style={{
            msGridColumn: msGrid.col,
            msGridRow: msGrid.row,
            msGridRowAlign: 'center',
            msGridColumnAlign: 'center',
         }}
      >
         <div className={classes.imageContainer}>
            <Img
               fixed={showImage}
               className={classes.cardImage}
               alt={impact.alt}
            />
         </div>
         <div className={classes.textOverlay}>
            <div className={[classes.impactTitle, classes.pt22].join(' ')}>
               {impact.title}
            </div>
            <div className={classes.impactText}>{impact.text}</div>
         </div>
      </div>
   )
}

export default ImpactCard
