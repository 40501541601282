import React from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  Grid,
  Container,
  MenuItem,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import axios from 'axios'
import sharedStyles from '../styles/global'
import CssTextField from '../components/cssTextField'
import Alert from '@material-ui/lab/Alert'
import Modal from '../components/modal'
import Helmet from 'react-helmet'
import Recaptcha from 'react-recaptcha'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  radioGroup: {
    margin: theme.spacing(1, 0, 1, 2),
  },
  buttonProgress: {
    margingTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  pText: {
    fontStyle: 'italic',
    marginLeft: '5px',
    color: 'gray',
    lineHeight: '14px',
    paddingTop: '5px',
  },
  smallText: {
    paddingTop: '1px',
  },
}))

const VisitNASA = (props) => {
  //###############################################################
  const theme = useTheme()
  const css = useStyles(theme)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const serviceSubHeaderStyles = [
    css.bold,
    isLargeScreen ? css.pt26 : css.pt20,
  ].join(' ')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const validateReqEmail = (email, hook) => {
    //let hgt = document.getElementById("MissionCis").style.height;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(email.target.value)
    if (hook === 'setShowMsg') {
      isValid ? setShowMsg(false) : setShowMsg(true)
      // hgt=hgt +'20px'
    } else if (hook === 'setAltShowMsg' && email.target.value != '') {
      isValid ? setAltShowMsg(false) : setAltShowMsg(true)
      // hgt=hgt +'20px'
    } else if (email.target.value == '') {
      setAltShowMsg(false)
    }
  }
  const validatePhone = (nr, hook) => {
    // US and International phone number pattern
    const phoneRe = /^[\+]?[0-9]{1,4}[\s\-]?[\(]?[0-9]{1,6}[\)]?[-\s\.]?[0-9]{1,12}$/im

    let phoneNr = nr.target.value
    const digits = phoneNr.replace(/\D/g, '')
    const isValid = phoneRe.test(digits)

    if (hook === 'setPhoneMsg') {
      isValid ? setPhoneMsg(false) : setPhoneMsg(true)
    }
  }

  const recaptchaLoaded = () => {
    setIsCapchaLoaded(true)
  }
  const expCallBack = () => {
    setIsVerified(false)
  }
  const verifyCallback = () => {
    setIsVerified(true)
  }
  //###############################################################
  const [name, setName] = React.useState('')
  const [showMsg, setShowMsg] = React.useState(false)
  const [altShowMsg, setAltShowMsg] = React.useState(false)
  const [PhoneMsg, setPhoneMsg] = React.useState(false)
  const [isCapchaLoaded, setIsCapchaLoaded] = React.useState(false)
  const [isVerified, setIsVerified] = React.useState(false)
  const [valInstitutionType, setInstitutionType] = React.useState('')
  const [valInstitutionLocation, setInstitutionLocation] = React.useState('')
  const [isMailingAddressVisible, setMailingAddressVisibility] = React.useState(
    false,
  )
  const [valAcademicLevel, setAcademicLevel] = React.useState('')
  const [valProgramFormat, setProgramFormat] = React.useState('')
  const [ProgramTopicsArray, setProgramTopicsArray] = React.useState([])
  const [valTeleconferenceType, setTeleconferenceType] = React.useState('')

  const optInstitutionType = [
    'School/Homeschool',
    'Museum/Camp',
    'Club/Group/Team',
    'Other',
  ]
  const optInstitutionLocation = ['US States and territories', 'International']
  const optAcademicLevel = [
    'Kindergarten and younger',
    'Grade 1-3',
    'Grade 4-6',
    'Grade 7-8',
    'Grade 9-10',
    'Grade 11-12',
    'College',
    'Adults',
    'Various ages',
  ]
  const optProgramFormat = [
    'No preference',
    'Open-ended Q&A: 30 min',
    'Elementary and Middle School: 30 min',
    'High School and Above: 45 min',
  ]
  const optProgramTopics = [
    'Laser communications',
    'NASA intern opportunities',
    'NASA career opportunities',
    'Electromagnetic spectrum',
    'Acoustics and waves',
    'Magnetism',
    'Radio communications',
    'Search and rescue',
    'Space navigation',
    'Humans living and working in space',
  ]
  const optTeleconferenceType = [
    'Microsoft Teams (SCaN-provided)',
    'Google Meet (SCaN-provided)',
    'WebEx (SCaN-provided)',
    'Other host-provided (specify)',
  ]

  const handleProgramTopics = (event) => {
    const ProgramTopic = event.target.name
    const isChecked = event.target.checked

    setProgramTopicsArray((prevItems) =>
      isChecked
        ? [...prevItems, ProgramTopic]
        : prevItems.filter((prevItem) => prevItem !== ProgramTopic),
    )
  }

  const handleUSselection = (e) => {
    const selectedValue = e.target.value
    setInstitutionLocation(selectedValue)

    // Set the visibility of the multiline textbox based on the selected value
    setMailingAddressVisibility(selectedValue === 'US States and territories')
  }

  const [openIsLoading, setOpenIsLoading] = React.useState(false)
  const handleCloseIsLoading = () => {
    setOpenIsLoading(false)
  }
  const [OpenisError, setOpenIsError] = React.useState(false)
  const handleCloseIsError = () => {
    setOpenIsError(false)
  }
  const resetFormAndState = () => {
    // Resetting each state variable to its initial value
    setName('')
    setShowMsg(false)
    setIsCapchaLoaded(false)
    setIsVerified(false)

    // Reset CAPTCHA if applicable
    // resetCaptcha(); // Add your CAPTCHA reset logic here if necessary
  }
  const [successfullSubmitButton, setSuccessfullSubmitButton] = React.useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (showMsg || altShowMsg || !isCapchaLoaded || !isVerified || PhoneMsg) {
      return
   }

    const form = new FormData(event.target)
    const firstName = form.get('firstName')
    const lastName = form.get('lastName')
    setName(`Thank you ${firstName} ${lastName}`)
    let email = form.get('email')
    let phone = form.get('phone')
    let desiredDate = form.get('desiredDate')
    let audienceSize = form.get('audienceSize')
    let institutionName = form.get('institutionName')
    let institutionType = form.get('institutionType')
    let institutionLocation = form.get('institutionLocation')
    let academicLevel = form.get('academicLevel')
    let institutionMailingAddress = form.get('institutionMailingAddress')
    let programFormat = form.get('programFormat')
    let teleconferenceType = form.get('teleconferenceType')
    let otherInformation = form.get('otherInformation')
    let programTopics = ProgramTopicsArray.join(', ')

    setOpenIsLoading(true)
  

    const formData = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      InstitutionMailingAddress: institutionMailingAddress,
      DesiredDate: desiredDate,
      AudienceSize: audienceSize,
      InstitutionName: institutionName,
      InstitutionType: institutionType,
      InstitutionLocation: institutionLocation,
      AcademicLevel: academicLevel,
      ProgramFormat: programFormat,
      TeleconferenceType: teleconferenceType,
      OtherInformation: otherInformation,
      ProgramTopics: programTopics ? programTopics : 'None',
    }

    console.log('about to submit......')
    axios
      .post('/api/ContactUs/VisitNasaRequest', formData)
      .then(response => {
        setOpenIsLoading(false)
        console.log('submitted!')
        console.log(response.data)
        setName(`Thank you ${firstName} ${lastName}`)
        setOpen(true)
        resetFormAndState()
        setSuccessfullSubmitButton(true)
      })
      .catch(error => {
        setOpenIsLoading(false)

        console.error('There was an error sending the data', error)
        setOpenIsError(true)
      })
  }
  const [showRecaptcha, setShowRecaptcha] = React.useState(false);

  React.useEffect(() => {
     const timer = setTimeout(() => {
        setShowRecaptcha(true);
     }, 500); // Adjust the delay as needed
  
     return () => {
        clearTimeout(timer);
        setShowRecaptcha(false);
     };
  }, []);
  return (
    <Container
      component='main'
      maxWidth='md'
      style={{ backgroundColor: 'white', paddingTop: 20 }}
    >
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js`}
          async
          defer
        ></script>
      </Helmet>
      <form className={css.form} onSubmit={handleSubmit}>
        <p
          className={serviceSubHeaderStyles}
          style={{ marginBottom: 20, fontFamily: 'Open Sans' }}
        >
          Contact Information
        </p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='fname'
              name='firstName'
              variant='outlined'
              required
              fullWidth
              id='firstName'
              label='First Name'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='lastName'
              label='Last Name'
              name='lastName'
              autoComplete='lname'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              onChange={(e) => {
                validateReqEmail(e, 'setShowMsg')
              }}
            />
            <Alert
              severity='warning'
              style={{
                display: showMsg ? 'block' : 'none',
                color: 'red',
              }}
              icon={false}
            >
              Email is not valid!
            </Alert>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='phone'
              name='phone'
              variant='outlined'
              fullWidth
              id='phone'
              label='Work Telephone'
              autoFocus
              onChange={(e) => {
                validatePhone(e, 'setPhoneMsg')
              }}
            />
            <Alert
              severity='warning'
              style={{
                display: PhoneMsg ? 'block' : 'none',
                color: 'red',
              }}
              icon={false}
            >
              Phone number is not valid, please use number only.
            </Alert>
          </Grid>
        </Grid>

        <p
          className={serviceSubHeaderStyles}
          style={{
            marginTop: 20,
            marginBottom: 20,
            fontFamily: 'Open Sans',
          }}
        >
          Institution Information
        </p>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='institutionName'
              name='institutionName'
              variant='outlined'
              required
              fullWidth
              id='institutionName'
              label='Institution Name'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='institutionType'
              label='Institution Type'
              name='institutionType'
              autoComplete='institutionType'
              select
              value={valInstitutionType}
              onChange={(e) => {
                setInstitutionType(e.target.value)
              }}
            >
              {optInstitutionType.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='institutionLocation'
              label='Institution Location'
              name='institutionLocation'
              autoComplete='institutionLocation'
              select
              value={valInstitutionLocation}
              onChange={handleUSselection}
            >
              {optInstitutionLocation.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='academicLevel'
              label='Academic Level'
              name='academicLevel'
              autoComplete='academicLevel'
              select
              value={valAcademicLevel}
              onChange={(e) => {
                setAcademicLevel(e.target.value)
              }}
            >
              {optAcademicLevel.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          {isMailingAddressVisible && (
            <Grid item xs={12} sm={6} style={{ marginTop: '12px' }}>
              <CssTextField
                InputLabelProps={{
                  classes: {
                    root: css.textFieldLabel,
                  },
                }}
                variant='outlined'
                fullWidth
                id='institutionMailingAddress'
                label='Institution Mailing Address'
                name='institutionMailingAddress'
                autoComplete='institutionMailingAddress'
                multiline
                rows={6}
              />
            </Grid>
          )}
        </Grid>

        <p
          className={serviceSubHeaderStyles}
          style={{
            marginTop: 20,
            marginBottom: 20,
            fontFamily: 'Open Sans',
          }}
        >
          Visit Information
        </p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='programFormat'
              label='Program Format'
              name='programFormat'
              autoComplete='programFormat'
              select
              value={valProgramFormat}
              onChange={(e) => {
                setProgramFormat(e.target.value)
              }}
            >
              {optProgramFormat.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            style={{
              border: '1px solid #1d3787',
              marginTop: '20px',
              marginLeft: '4px',
              marginRight: '4px',
              marginBottom: '15px',
              padding: '15px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Typography
              variant='subtitle1'
              style={{
                position: 'absolute',
                top: '-16px',
                left: '10px',
                backgroundColor: 'white',
                padding: '4px 7px',
                borderRadius: '5px 0 0 0',
                fontSize: '13px',
              }}
            >
              Program Topic (select up to three)
            </Typography>
            <Grid container spacing={1}>
              <p className={css.pText}>
                <small className={css.smallText}>
                  Please share any special interest topics you have beyond
                  information about the Space Communications and Navigation
                  office.
                </small>
              </p>
              {optProgramTopics.map((ProgramTopic) => (
                <Grid item key={ProgramTopic} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ProgramTopic}
                        checked={ProgramTopicsArray.includes(ProgramTopic)}
                        onChange={handleProgramTopics}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: '0.9rem',
                          lineHeight: '1.1',
                          display: 'inline-block',
                        }}
                      >
                        {ProgramTopic}
                      </span>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='teleconferenceType'
              label='Teleconference Type'
              name='teleconferenceType'
              autoComplete='teleconferenceType'
              select
              value={valTeleconferenceType}
              onChange={(e) => {
                setTeleconferenceType(e.target.value)
              }}
            >
              {optTeleconferenceType.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
            <p className={css.pText}>
              <small className={css.smallText}>
                We will schedule a 15-minute audio/video check-in prior to our
                visitation date.
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='desiredDate'
              label='Desired Date'
              name='desiredDate'
              autoComplete='desiredDate'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                Your date(s) may be general or specific. Monday to Friday from
                9:00 a.m. to 5:00 p.m. Eastern Time (GMT–5) are strongly
                preferred.
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='audienceSize'
              label='Audience Size'
              name='audienceSize'
              autoComplete='audienceSize'
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: '12px',
            }}
          >
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='otherInformation'
              label='Other Information'
              name='otherInformation'
              autoComplete='otherInformation'
              multiline
              rows={6}
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                Please share any language needs, disabilities, or special
                accommodation needs. Please also share any special interests
                that would make us a better fit for your specific classroom: if
                you’re studying about communications soon, if your group is
                involved in particular STEM-focused extracurriculars, or
                similar.
                <br />
                <br />
              </small>
            </p>
          </Grid>
        </Grid>
        {showRecaptcha && (

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} style={{ marginTop: 5 }}>
            <Recaptcha
              sitekey="6LfFxL4ZAAAAAGeU0rcZwrwD7InxYOu7kUx1AK-H"
              render="explicit"
              verifyCallback={verifyCallback}
              onloadCallback={recaptchaLoaded}
              expiredCallback={expCallBack}
              id="recaptcha-visitNasa"
            />
          </Grid>
          {/* <div
            class='g-recaptcha'
            data-sitekey='6LfFxL4ZAAAAAGeU0rcZwrwD7InxYOu7kUx1AK-H'
          ></div> */}
        </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {!successfullSubmitButton && (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={css.submit}
            >
              {' '}
              Send Request
            </Button>
            )}
             {successfullSubmitButton && (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={css.submit}
              disabled
            >
              {' '}
              Request Successfully Submitted
            </Button>
            )}
          </Grid>
        </Grid>
      </form>

      <Modal title={name} isOpen={open} handleClose={handleClose}>
        <h4>Your form has been successfully submitted.</h4>
        {/* <h4 style={{ marginTop: 5 }}> Please expect a response {valUrgencySub}.</h4> */}
      </Modal>
      <Modal
        // title={'Submitting please wait'}
        isOpen={openIsLoading}
        handleClose={handleCloseIsLoading}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          color: '#333',
          fontFamily: '"Helvetica Neue", sans-serif',
        }}
      >
        <h2 style={{ margin: '0 0 20px 0' }}>Submitting, please wait...</h2>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Modal>

      <Modal isOpen={OpenisError} handleClose={handleCloseIsError}>
        <h4>Something went wrong, please try again later.</h4>
      </Modal>
    </Container>
  )
}

export default VisitNASA
