/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
   makeStyles,
   createTheme,
   MuiThemeProvider,
} from '@material-ui/core/styles'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'

import Header from './header'
import Footer from './footer'

const useStyles = makeStyles(theme => ({
   site: {
      margin: '0 auto',
      paddingTop: '110px',
      [theme.breakpoints.down('sm')]: {
         paddingTop: '88px',
      },
      maxWidth: '1200px',
      backgroundColor: 'white',
   },
}))

const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
      query SiteTitleQuery {
         site {
            siteMetadata {
               title
            }
         }
      }
   `)

   const theme = createTheme({
      palette: {
         primary: {
            main: '#1D3787',
         },
         secondary: {
            light: '#3E63D3',
            main: '#1F357E',
            dark: '#0C1D52',
         },
      },
      overrides: {
         MuiPaper: {
            root: {
               color: '#1D3787',
               fontFamily: 'Open Sans',
            },
         },
      },
   })
   const classes = useStyles(theme)

   // Function to open external links in a new tab
   useEffect(() => {
      const handleClick = event => {
         console.time('externalLink')

         // el is the element that was clicked
         var el = event.target

         // This loop continues as long as we don't have an anchor element with an href attribute
         // It keeps going up in the node tree until it finds such an anchor element or runs out of parent elements
         while (
            el &&
            (typeof el.tagName === 'undefined' ||
               el.tagName.toLowerCase() !== 'a' ||
               !el.href)
         ) {
            el = el.parentNode
         }

         // If we've found an anchor element with an href
         if (el && el.href) {
            // The full URL of the link
            var link = el.href
            // A URL object to easily access the parts of the URL
            var linkUrl = new URL(link)

            // If the link's hostname is not the same as the current location's hostname
            // This means that the link is external
            if (linkUrl.hostname !== location.hostname) {
               // Open the link in a new tab
               var newWindow = window.open(link, '_blank')
               // Prevent the new tab to have a reference to the original tab
               if (newWindow) newWindow.opener = null
               // Prevent the browser's default click action
               event.preventDefault()
            }
         }
         console.timeEnd('externalLink')
      }

      // Add the handleClick function as an event listener to the click event
      window.addEventListener('click', handleClick)

      // The return function of useEffect is called before the component unmounts
      // This is for cleanup purposes
      return () => {
         window.removeEventListener('click', handleClick)
      }
   }, [])

   return (
      <>
         <Helmet
            link={[
               {
                  rel: 'shortcut icon',
                  type: 'image/x-icon',
                  href: `${favicon}`,
               },
            ]}
         ></Helmet>
         <MuiThemeProvider theme={theme}>
            <div className={classes.site}>
               <Header siteTitle={data.site.siteMetadata.title} />
               <section role="main" className={classes.main}>
                  {children}
               </section>
               <Footer />
            </div>
         </MuiThemeProvider>
      </>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
}

export default Layout
