import React from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  Grid,
  Container,
  MenuItem,
  //Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import Helmet from 'react-helmet'
import Recaptcha from 'react-recaptcha'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Modal from './modal'
import CssTextField from './cssTextField'
import sharedStyles from '../styles/global'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  radioGroup: {
    margin: theme.spacing(1, 0, 1, 2),
  },
  buttonProgress: {
    margingTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  pText: {
    fontStyle: 'italic',
    marginLeft: '5px',
    color: 'gray',
  },
  smallText: {
    paddingTop: '1px',
  },
}))

const RequestServices = (props) => {
  const theme = useTheme()
  const css = useStyles(theme)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const serviceSubHeaderStyles = [
    css.bold,
    isLargeScreen ? css.pt26 : css.pt20,
  ].join(' ')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const validateReqEmail = (email, hook) => {
    //let hgt = document.getElementById("MissionCis").style.height;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(email.target.value)
    if (hook === 'setShowMsg') {
      isValid ? setShowMsg(false) : setShowMsg(true)
      // hgt=hgt +'20px'
    } else if (hook === 'setAltShowMsg' && email.target.value != '') {
      isValid ? setAltShowMsg(false) : setAltShowMsg(true)
      // hgt=hgt +'20px'
    } else if (email.target.value == '') {
      setAltShowMsg(false)
    }
  }
  const validatePhone = (nr, hook) => {
    const phoneRe = /^[\?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/im
    let phoneNr = nr.target.value
    var digits = phoneNr.replace(/\D/g, '')
    const isValid = phoneRe.test(digits)
    if (hook === 'setPhneMsg') {
      isValid ? setPhneMsg(false) : setPhneMsg(true)
    } else if (hook === 'setAltPhoneMsg' && digits != '') {
      isValid ? setAltPhoneMsg(false) : setAltPhoneMsg(true)
    } else if (digits == '' && hook === 'setAltPhoneMsg') {
      setAltPhoneMsg(false)
    } else if (hook === 'setSecAltPhoneMsg' && digits != '') {
      isValid ? setSecAltPhone(false) : setSecAltPhone(true)
    } else if (digits == '' && hook === 'setSecAltPhoneMsg') {
      setSecAltPhone(false)
    } else if (hook === 'setSecPhoneMsg' && digits != '') {
      isValid ? setSecPhone(false) : setSecPhone(true)
    } else if (digits == '' && hook === 'setSecPhoneMsg') {
      setSecPhone(false)
    }
  }

  const recaptchaLoaded = () => {
    setIsCapchaLoaded(true)
  }
  const expCallBack = () => {
    setIsVerified(false)
  }
  const verifyCallback = () => {
    setIsVerified(true)
  }
  //###############################################################
  const [name, setName] = React.useState('')
  const [showMsg, setShowMsg] = React.useState(false)
  const [altShowMsg, setAltShowMsg] = React.useState(false)
  const [PhoneMsg, setPhneMsg] = React.useState(false)
  const [altPhoneMsg, setAltPhoneMsg] = React.useState(false)
  const [SecAltPhoneMsg, setSecAltPhone] = React.useState(false)
  const [secPhoneMsg, setSecPhone] = React.useState(false)
  const [isCapchaLoaded, setIsCapchaLoaded] = React.useState(false)
  const [isVerified, setIsVerified] = React.useState(false)
  const [valmission, setvalmission] = React.useState('')
  const [valMissionPlatform, setMissionPlatform] = React.useState('')
  const [valmissionPhase, setmissionPhase] = React.useState('')
  const [valFundingSource, setFundingSource] = React.useState('')
  const [valMissionOrbits, setMissionOrbits] = React.useState('')
  // const [valUrgency, setUrgency] = React.useState('')
  // const [valUrgencySub, setvalUrgencySub] = React.useState('')
  const [valMissionScienceType, setMissionScienceType] = React.useState('')
  const [valTelemetry, setTelemetry] = React.useState('')
  const [valDataLatency, setDataLatency] = React.useState('')
  const [valRangingService, setRangingService] = React.useState('')
  const [valOpticalComm, setOpticalComm] = React.useState('')
  const [valServiceType, setServiceType] = React.useState('')

  const [
    MissionPhaseServicesArray,
    setMissionPhaseServicesArray,
  ] = React.useState([])
  const optMissionPlatform = [
    'Single',
    'Multiple platforms in formation ',
    'Multiple distributed',
    'Hosted payload',
  ]
  const optmissionPhase = [
    'Pre-Phase A: Conceptual',
    'Phase A: Preliminary Analysis',
    'Phase B: Preliminary Design',
    'Phase C: Final Design and Fabrication',
    'Phase D: System Assembly, integration and test',
    'Phase E: Operations',
    'Phase F: Closeout ',
  ]
  const optFundingSource = [
    'NASA - Directed',
    'NASA - AO',
    'Other Government Agency',
    'Self-Funded',
    'Government Grant',
    'Not Yet Funded',
    'Other ',
  ]
  const optMissionScienceType = [
    'Heliophysics',
    'Astrophysics',
    'Earth Science',
    'Planetary Science',
    'Human Space Flight',
    'Technology Demonstration',
    'Other',
  ]
  const optMissionPhaseServices = [
    'Power flight (Launch through separation)',
    'Launch and Early Orbit (LEOP; separation - L+4 days)',
    'In-Orbit Checkout (IOC; e.g., L+5 - L+30 days)',
    'Nominal Operations (NOM OPS; primary mission duration)',
    'End of Mission (EOM)',
  ]
  const optTelemetry = [
    'Very High - (above 25 Tbits)',
    'High (2-25 Tbits)',
    'Medium (50 Gbits- 2 Tbits)',
    'Low (5-50 Gbits)',
    'Very Low (less than 5 Gbits)',
  ]
  const optOrbits = [
    'Earth Surface (Terrestrial)',
    'Low Earth Orbit (LEO)',
    'Medium Earth Orbit (MEO)',
    'Geo Earth orbit (GEO)',
    'Cis-Lunar',
    'Earth-Sun LaGrange 1/2 (L1/L2)',
    'Highly Elliptical (HEO)',
    'Lunar Orbit',
    'Deep Space / Planetary',
    'Launch / Flyout Trajectory',
    'Other',
  ]
  const optDataLatency = [
    '>12 hrs',
    '6-12 hrs',
    '2-6 hrs',
    '1-2 hrs',
    'Near-continuous coverage ',
  ]
  const optRangingService = ['Tone', 'Pseudo-Noise (PN) Ranging', 'Other/TBD']
  const optOpticalComm = ['Yes', 'No']
  const optServiceType = ['Space Relay (SR)', 'Direct to Earth (DTE)', 'Both']
  const handleMissionPhaseServices = (event) => {
    const MissionPhaseService = event.target.name
    const isChecked = event.target.checked

    setMissionPhaseServicesArray((prevItems) =>
      isChecked
        ? [...prevItems, MissionPhaseService]
        : prevItems.filter((prevItem) => prevItem !== MissionPhaseService),
    )
  }

  // const handleUrgency = event =>{
  //    setUrgency(event.target.value)
  //    if(event.target.value == "Medium - response requested within two weeks"){
  //       setvalUrgencySub("within two weeks")
  //    }else if(event.target.value == "High - immediate response requested"){
  //       setvalUrgencySub("as soon as possible")
  //    }else{
  //       setvalUrgencySub("within nominal workflow times")
  //    }
  // }
  const [openIsLoading, setOpenIsLoading] = React.useState(false)
  const handleCloseIsLoading = () => {
    setOpenIsLoading(false)
  }
  const [OpenisError, setOpenIsError] = React.useState(false)
  const handleCloseIsError = () => {
    setOpenIsError(false)
  }
  const resetFormAndState = () => {
    // Resetting each state variable to its initial value
    setName('')
    setShowMsg(false)
    setAltShowMsg(false)
    setPhneMsg(false)
    setAltPhoneMsg(false)
    setSecAltPhone(false)
    setSecPhone(false)
    setIsCapchaLoaded(false)
    setIsVerified(false)
    setvalmission('')
    setMissionPlatform('')
    setmissionPhase('')
    setFundingSource('')
    setMissionOrbits('')
    // setUrgency(''); Uncomment if you want to reset this state as well
    // setvalUrgencySub(''); Uncomment if you want to reset this state as well
    setMissionScienceType('')
    setTelemetry('')
    setDataLatency('')
    setRangingService('')
    setOpticalComm('')
    setServiceType('')

    // Reset CAPTCHA if applicable
    // resetCaptcha(); // Add your CAPTCHA reset logic here if necessary
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setOpenIsLoading(true)
    if (!isVerified || !isCapchaLoaded) {
      return
    }
    const form = new FormData(event.target)
    const firstName = form.get('firstName')
    const lastName = form.get('lastName')
    setName(`Thank you ${firstName} ${lastName}`)
    let missionRole = form.get('missionRole')
    let email = form.get('email')
    let phone = form.get('phone')
    let altPhone = form.get('altPhone')
    let CompEmployer = form.get('CompEmployer')
    let SponsoringOrganization = form.get('SponsoringOrganization')
    let OrganizationType = form.get('OrganizationType')
    let NationalAffiliation = form.get('NationalAffiliation')
    let MissionName = form.get('MissionName')
    let LaunchDate = form.get('LaunchDate')
    let MissionPhaseService = `${MissionPhaseServicesArray}`.replace(/,/g, ', ')
    let missionPhase = form.get('missionPhase')
    let missionDesc = form.get('missionDescription')
    let MissionPlatform = form.get('MissionPlatform')
    let FundingSource = form.get('FundingSource')
    let NASAOpportunity = form.get('NASAOpportunity')
    let MissionOrbits = form.get('MissionOrbits')
    let MissionDuration = form.get('missionDuration')

    if (
      showMsg ||
      altShowMsg ||
      !isCapchaLoaded ||
      !isVerified ||
      PhoneMsg ||
      altPhoneMsg
    ) {
      return
    }

    const formData = {
      FirstName: firstName,
      LastName: lastName,
      MissionRole: missionRole,
      Email: email,
      Phone: phone,
      AltPhone: altPhone,
      CompEmployer: CompEmployer,
      OrganizationType: OrganizationType,
      SponsoringOrganization: SponsoringOrganization,
      NationalAffiliation: NationalAffiliation,
      MissionName: MissionName,
      MissionPlatform: MissionPlatform,
      LaunchDate: LaunchDate,
      MissionPhaseService: MissionPhaseService,
      MissionPhase: missionPhase,
      FundingSource: FundingSource,
      NASAOpportunity: NASAOpportunity,
      MissionScienceType: valMissionScienceType,
      MissionOrbits: MissionOrbits,
      MissionDuration: MissionDuration,
      Telemetry: valTelemetry,
      DataLatency: valDataLatency,
      RangingService: valRangingService,
      OpticalComm: valOpticalComm,
      ServiceType: valServiceType,
      MissionDesc: missionDesc,
    }

    axios
      .post('/api/ContactUs/SendEmailRequest', formData)
      .then((response) => {
        setOpenIsLoading(false)
        console.log(response.data)
        setName(`Thank you ${firstName} ${lastName}`)
        setOpen(true)
        resetFormAndState()
      })
      .catch((error) => {
        setOpenIsLoading(false)

        console.error('There was an error sending the data', error)
        setOpenIsError(true)
      })
  }

  return (
    <Container
      component='main'
      maxWidth='md'
      style={{ backgroundColor: 'white', paddingTop: 20 }}
    >
      <Helmet>
        <script
          async
          defer
          src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
        />
      </Helmet>
      <form className={css.form} onSubmit={handleSubmit}>
        <p
          className={serviceSubHeaderStyles}
          style={{ marginBottom: 20, fontFamily: 'Open Sans' }}
        >
          Contact Information
        </p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='fname'
              name='firstName'
              variant='outlined'
              required
              fullWidth
              id='firstName'
              label='First Name'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='lastName'
              label='Last Name'
              name='lastName'
              autoComplete='lname'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='missionRole'
              label='Role'
              name='missionRole'
              autoComplete='missionRole'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                Examples: Principal Investigator, System Engineer, Study Manager
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              onChange={(e) => {
                validateReqEmail(e, 'setShowMsg')
              }}
            />
            <Alert
              severity='warning'
              style={{
                display: showMsg ? 'block' : 'none',
                color: 'red',
              }}
              icon={false}
            >
              Email is not valid!
            </Alert>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='phone'
              name='phone'
              variant='outlined'
              fullWidth
              id='phone'
              label='Phone Number'
              autoFocus
              onChange={(e) => {
                validatePhone(e, 'setPhneMsg')
              }}
            />
            <Alert
              severity='warning'
              style={{
                display: PhoneMsg ? 'block' : 'none',
                color: 'red',
              }}
              icon={false}
            >
              Phone number is not valid!
            </Alert>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='altPhone'
              label='Alternative Phone Number'
              name='altPhone'
              autoComplete='altPhone'
              onChange={(e) => {
                validatePhone(e, 'setAltPhoneMsg')
              }}
            />
            <Alert
              severity='warning'
              style={{
                display: altPhoneMsg ? 'block' : 'none',
                color: 'red',
              }}
              icon={false}
            >
              Phone number is not valid!
            </Alert>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              autoComplete='CompEmployer'
              name='CompEmployer'
              variant='outlined'
              fullWidth
              id='CompEmployer'
              label='Company/Employer'
              autoFocus
              required
            />
          </Grid>
        </Grid>
        <p
          className={serviceSubHeaderStyles}
          style={{
            marginTop: 20,
            marginBottom: 20,
            fontFamily: 'Open Sans',
          }}
        >
          Request Information
        </p>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='OrganizationType'
              label='Organization Type'
              name='OrganizationType'
              autoComplete='OrganizationType'
              select
              value={valmission}
              onChange={(e) => {
                setvalmission(e.target.value)
              }}
            >
              <MenuItem
                key="US Government Agency-NASA"
                style={{ whiteSpace: 'normal' }}
                value="US Government Agency-NASA"
              >
                US Government Agency &mdash; NASA
              </MenuItem>
              <MenuItem
                key="US Government Agency-Non-NASA"
                style={{ whiteSpace: 'normal' }}
                value="US Government Agency-Non-NASA"
              >
                US Government Agency &mdash; Non-NASA
              </MenuItem>
              <MenuItem
                key={'International Government Agency'}
                value={'International Government Agency'}
                style={{ whiteSpace: 'normal' }}
              >
                International Government Agency
              </MenuItem>
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='SponsoringOrganization'
              label='Sponsoring Organization'
              name='SponsoringOrganization'
              autoComplete='SponsoringOrganization'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                List the organization sponsoring the mission (e.g. NASA SMD,
                ESA, University of Maryland)
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='NationalAffiliation'
              label='Mission National Affiliation'
              name='NationalAffiliation'
              autoComplete='NationalAffiliation'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                List the country which is affiliated with the mission (e.g. USA,
                Japan)
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='MissionName'
              label='Mission Name'
              name='MissionName'
              autoComplete='MissionName'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='MissionPlatform'
              label='Mission Platform(s)'
              name='MissionPlatform'
              autoComplete='MissionPlatform'
              select
              value={valMissionPlatform}
              onChange={(e) => {
                setMissionPlatform(e.target.value)
              }}
            >
              {optMissionPlatform.map((platforms) => (
                <MenuItem
                  key={platforms}
                  value={platforms}
                  style={{ whiteSpace: 'normal' }}
                >
                  {platforms}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='LaunchDate'
              label='Launch Date/Year'
              name='LaunchDate'
              autoComplete='LaunchDate'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                Please input the launch date or year. If unknown, please enter
                "TBD"
              </small>
            </p>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
                  <CssTextField
                     InputLabelProps={{
                        classes: {
                           root: css.textFieldLabel,
                        },
                     }}
                     variant="outlined"
                     fullWidth
                     id="Urgency"
                     label="Urgency"
                     name="Urgency"
                     autoComplete="Urgency"
                     select
                     required
                     value={valUrgency}
                     onChange={e=>{handleUrgency(e)}}
                     ><MenuItem
                           key={'high'}
                           value={'High - immediate response requested'}
                           style={{ whiteSpace: 'normal' }}
                        >
                        High - immediate response requested
                     </MenuItem>
                     <MenuItem
                              key={'md'}
                              value={'Medium - response requested within two weeks'}
                              style={{ whiteSpace: 'normal' }}
                           >
                        Medium - response requested within two weeks
                     </MenuItem>
                     <MenuItem
                              key={'nm'}
                              value={'Normal - response requested within nominal workflow times'}
                              style={{ whiteSpace: 'normal' }}
                           >
                        Normal - response requested within nominal workflow times
                     </MenuItem>
                  </CssTextField>
               </Grid> */}
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='missionDuration'
              label='Mission Duration'
              name='missionDuration'
              autoComplete='missionDuration'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='missionPhase'
              label='Current Mission Phase'
              name='missionPhase'
              autoComplete='missionPhase'
              select
              value={valmissionPhase}
              onChange={(e) => {
                setmissionPhase(e.target.value)
              }}
            >
              {optmissionPhase.map((phase) => (
                <MenuItem
                  style={{ color: 'orange' }}
                  key={phase}
                  value={phase}
                  style={{ whiteSpace: 'normal' }}
                >
                  {phase}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              required
              fullWidth
              id='FundingSource'
              label='Funding Source'
              name='FundingSource'
              autoComplete='FundingSource'
              select
              value={valFundingSource}
              onChange={(e) => {
                setFundingSource(e.target.value)
              }}
            >
              {optFundingSource.map((source) => (
                <MenuItem
                  key={source}
                  style={{ whiteSpace: 'normal' }}
                  value={source}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='NASAOpportunity'
              label='NASA Opportunity'
              name='NASAOpportunity'
              autoComplete='NASAOpportunity'
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                List the NASA Funding Opportunity such as Heliophysics SMEX, Earth Science MIDEX, ROSES, etc.
              </small>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* mission science type--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='MissionScienceType'
              label='Mission/Science Type'
              name='MissionScienceType'
              autoComplete='MissionScienceType'
              select
              value={valMissionScienceType}
              onChange={(e) => {
                setMissionScienceType(e.target.value)
              }}
            >
              {optMissionScienceType.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                  style={{ whiteSpace: 'normal' }}
                >
                  {source}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='MissionOrbits'
              label='Mission Orbit(s)'
              name='MissionOrbits'
              autoComplete='MissionOrbits'
              select
              value={valMissionOrbits}
              onChange={(e) => {
                setMissionOrbits(e.target.value)
              }}
            >
              {optOrbits.map((orbits) => (
                <MenuItem
                  key={orbits}
                  value={orbits}
                  style={{ whiteSpace: 'normal' }}
                >
                  {orbits}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid
            MissionPhaseService
            xs={12}
            sm={12}
            style={{
              border: '1px solid #1d3787',
              marginTop: '12px',
              marginLeft: '4px',
              marginRight: '4px',
              marginBottom: '5px',
              padding: '8px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Typography
              variant='subtitle1'
              style={{
                position: 'absolute',
                top: '-16px',
                left: '10px',
                backgroundColor: 'white',
                padding: '4px 7px',
                borderRadius: '5px 0 0 0',
                fontSize: '13px',
              }}
            >
              {' '}
              Mission Phase for which services are required
            </Typography>
            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '10px',
              }}
            >
              {optMissionPhaseServices.map((MissionPhaseService) => (
                <FormControlLabel
                  key={MissionPhaseService}
                  control={
                    <Checkbox
                      name={MissionPhaseService}
                      checked={MissionPhaseServicesArray.includes(
                        MissionPhaseService,
                      )}
                      onChange={handleMissionPhaseServices}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: '0.9rem',
                        lineHeight: '1.1',
                        display: 'inline-block',
                      }}
                    >
                      {MissionPhaseService}
                    </span>
                  }
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* telemetry/science data volume--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='Telemetry'
              label='Telemetry/Science Data Volume Requirement per day'
              name='Telemetry'
              autoComplete='Telemetry'
              select
              value={valTelemetry}
              onChange={(e) => {
                setTelemetry(e.target.value)
              }}
            >
              {optTelemetry.map((telemetry) => (
                <MenuItem
                  key={telemetry}
                  value={telemetry}
                  style={{ whiteSpace: 'normal' }}
                >
                  {telemetry}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* data latency--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='DataLatency'
              label='Data Latency (Max Contact Separation)'
              name='DataLatency'
              autoComplete='DataLatency'
              select
              value={valDataLatency}
              onChange={(e) => {
                setDataLatency(e.target.value)
              }}
            >
              {optDataLatency.map((latency) => (
                <MenuItem
                  key={latency}
                  value={latency}
                  style={{ whiteSpace: 'normal' }}
                >
                  {latency}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* ranging services required--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='RangingService'
              label='Ranging Services Required'
              name='RangingService'
              autoComplete='RangingService'
              select
              value={valRangingService}
              onChange={(e) => {
                setRangingService(e.target.value)
              }}
            >
              {optRangingService.map((rangSrv) => (
                <MenuItem
                  key={rangSrv}
                  value={rangSrv}
                  style={{ whiteSpace: 'normal' }}
                >
                  {rangSrv}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* would you consider--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='opticalComm'
              label='Would you consider optical communication services?'
              name='opticalComm'
              autoComplete='opticalComm'
              select
              value={valOpticalComm}
              onChange={(e) => {
                setOpticalComm(e.target.value)
              }}
            >
              {optOpticalComm.map((opt) => (
                <MenuItem
                  key={opt}
                  value={opt}
                  style={{ whiteSpace: 'normal' }}
                >
                  {opt}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {' '}
            {/* service types--> */}
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='ServiceType'
              label='Service Type'
              name='ServiceType'
              autoComplete='ServiceType'
              select
              value={valServiceType}
              onChange={(e) => {
                setServiceType(e.target.value)
              }}
            >
              {optServiceType.map((optSvrType) => (
                <MenuItem
                  key={optSvrType}
                  value={optSvrType}
                  style={{ whiteSpace: 'normal' }}
                >
                  {optSvrType}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: '12px',
            }}
          >
            <CssTextField
              InputLabelProps={{
                classes: {
                  root: css.textFieldLabel,
                },
              }}
              variant='outlined'
              fullWidth
              id='missionDescription'
              label='Additional Information'
              name='missionDescription'
              autoComplete='missionDescription'
              multiline
              rows={6}
            />
            <p className={css.pText}>
              <small className={css.smallText}>
                Please describe the nature of the request(info only,planning
                support,cost estimation) and any additional information
                including any unique requirements.
              </small>
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} style={{ marginTop: 5 }}>
            <Recaptcha
              sitekey='6LfFxL4ZAAAAAGeU0rcZwrwD7InxYOu7kUx1AK-H'
              render='explicit'
              verifyCallback={verifyCallback}
              onloadCallback={recaptchaLoaded}
              expiredCallback={expCallBack}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={css.submit}
            >
              {' '}
              Send Request
            </Button>
          </Grid>
        </Grid>
      </form>

      <Modal title={name} isOpen={open} handleClose={handleClose}>
        <h4>Your service inquiry form has been successfully submitted.</h4>
        {/* <h4 style={{ marginTop: 5 }}> Please expect a response {valUrgencySub}.</h4> */}
      </Modal>

      <Modal
        // title={'Submitting please wait'}
        isOpen={openIsLoading}
        handleClose={handleCloseIsLoading}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          color: '#333',
          fontFamily: '"Helvetica Neue", sans-serif',
        }}
      >
        <h2 style={{ margin: '0 0 20px 0' }}>Submitting, please wait...</h2>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Modal>

      <Modal isOpen={OpenisError} handleClose={handleCloseIsError}>
        <h4>Something went wrong, please try again later.</h4>
      </Modal>
    </Container>
  )
}

export default RequestServices
