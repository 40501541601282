import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   headingSpacing: {
      margin: theme.spacing(4, 0, 4, 0),
   },
}))

const SubHeading = ({ text }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   return (
      <div
         className={[
            css.sansProSemiBold,
            css.pt40,
            css.uppercase,
            css.regular,
            css.headingSpacing,
         ].join(' ')}
      >
         {text}
      </div>
   )
}

export default SubHeading
