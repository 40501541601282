import React from 'react'
import TextBlock from '../news/textBlock'
import ImageOverlayWithCaption from '../imageOverlayWithCaption'

const showCaption = true

const FullWidth = ({ css, article }) => {
   const cssSettings = {
      crop: false,
      container: {
         maxHeight: '400px',
      },
      image: {},
      overlay: { background: '' },
   }

   return (
      <>
         <TextBlock
            subHeading={article.SubHeading1}
            text={article.Text1.data.Text1}
         />
         {article.image1 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image1.File}
                  alt={article.image1.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image1.Caption}
                  credit={article.image1.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading2}
            text={article.Text2.data.Text2}
         />
         {article.image2 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image2.File}
                  alt={article.image2.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image2.Caption}
                  credit={article.image2.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading3}
            text={article.Text3.data.Text3}
         />
         {article.image3 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image3.File}
                  alt={article.image3.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image3.Caption}
                  credit={article.image3.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading4}
            text={article.Text4.data.Text4}
         />
         {article.image4 && (
            <div className={css.imageSpacing}>
               <ImageOverlayWithCaption
                  image={article.image4.File}
                  alt={article.image4.Alt}
                  css={cssSettings}
                  showCaption={showCaption}
                  caption={article.image4.Caption}
                  credit={article.image4.Credit}
               ></ImageOverlayWithCaption>
            </div>
         )}

         <TextBlock
            subHeading={article.SubHeading5}
            text={article.Text5.data.Text5}
         />
      </>
   )
}

export default FullWidth
