import React from 'react'
import Markdown from 'react-markdown/with-html'
import ProjectPageImage from '../components/projectPageImage'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   subHeader: {
      margin: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.down('xs')]: {
         margin: theme.spacing(0, 0, 1, 0),
      },
   },
   leftSideImageLayout: {
      margin: theme.spacing(2, 0),
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
         margin: theme.spacing(1, 0),
      },
   },
   textSide: {
      padding: theme.spacing(0, 4, 0, 0),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0),
      },
   },
   spanTwoCols: {
      gridColumn: '1 / span 2',
      padding: theme.spacing(0),
   },
   bulletStyling: {
      '& ul': {
         opacity: '0.9',
      },
   },
}))

const ProjectParagraphImageRight = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { subheader, paragraphText, imageInfo, fluidImage } = props

   const textSideClasses = [
      css.textSide,
      css.bulletStyling,
      fluidImage ? '' : css.spanTwoCols,
   ].join(' ')
   return (
      <div className={css.leftSideImageLayout}>
         <div className={textSideClasses}>
            {subheader ? (
               <h2 className={[css.subHeader, css.regular].join(' ')}>
                  {subheader}
               </h2>
            ) : null}
            <Markdown source={paragraphText} escapeHtml={false} />
         </div>
         {fluidImage ? (
            <ProjectPageImage imageInfo={imageInfo} fluidImage={fluidImage} />
         ) : null}
      </div>
   )
}

export default ProjectParagraphImageRight
