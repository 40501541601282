import React, { useEffect } from 'react'
import { useTheme, makeStyles, IconButton, Dialog } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { AccessTime } from '@material-ui/icons'

import { withStyles } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'

const styles = theme => ({
   ...sharedStyles(theme),
   root: {
      margin: 0,
      padding: theme.spacing(1, 1, 1, 2),
      gridTemplateColumns: 'max-content 1fr',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing(1),
      alignItems: 'center',
   },
   closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      top: '1px',
      right: '1px',
   },
   title: {
      justifySelf: 'start',
      marginRight: '20px',
   },
})
const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
}))

const DialogTitle = withStyles(styles)(props => {
   const { children, classes, onClose } = props
   return (
      <MuiDialogTitle disableTypography className={classes.root}>
         <Typography variant="h6">{children}</Typography>
         {onClose ? (
            <IconButton
               aria-label="close"
               className={classes.closeButton}
               onClick={onClose}
            >
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
   )
})

const DialogContent = withStyles(theme => ({
   root: {
      padding: theme.spacing(2),
   },
}))(MuiDialogContent)

const CollateralModal = withStyles(styles)(props => {
   const { title, publish, isOpen, handleClose, children, classes } = props
   const theme = useTheme()
   const css = useStyles(theme)
   return (
      <Dialog
         onClose={handleClose}
         aria-labelledby="customized-dialog-title"
         open={isOpen}
         maxWidth="xs"
      >
         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div className={classes.title}>{title}</div>
            {publish && (
               <div
                  className={[
                     css.openSans,
                     css.pt18,
                     css.italic,
                     css.opacity80,
                  ].join(' ')}
                  style={{
                     display: 'flex',
                     alignItems: 'center',
                  }}
               >
                  {publish && (
                     <div>
                        <AccessTime style={{ fontSize: '12px' }} />
                        &nbsp;
                        {publish}
                     </div>
                  )}
               </div>
            )}
         </DialogTitle>
         <DialogContent>{children}</DialogContent>
      </Dialog>
   )
})

export default CollateralModal
