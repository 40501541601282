import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ImageOverlay from '../components/imageOverlay'
import sharedStyles from '../styles/global'
import { AccessTime } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   titleSpacing: {
      paddingTop: '0.6rem',
      paddingBottom: '0.2rem',
      lineHeight: 1.2,
      color: '#3e63d3',
   },
   tagSpacing: {
      paddingBottom: '0.8rem',
   },
}))

const cssSettings = {
   crop: false,
   container: {
      '&:hover': {
         '& > div': {
            background: 'rgba(29,55,135,0.7)',
         },
         '& > span': {
            display: 'block',
         },
      },
   },
   overlay: {
      background: '',
   },
   text: {
      top: '10%',
      lineHeight: 1.25,
      display: 'none',
      paddingLeft: '15px',
   },
}

export const SmallerNewsPanel = props => {
   const theme = useTheme()
   const classes = useStyles(theme)

   const article = props.article

   //-- handle missing header image
   if (article.Header === undefined || article.Header === 'null') {
      article.Header = { File: null }
   }

   return (
      <div className={classes.smallerImage}>
         <Link to={`../news/${article.Slug}`}>
            <ImageOverlay
               image={article.Header?.File && article.Header.File.localFile ? article.Header.File.localFile : article.image1?.File.localFile ? article.image1.File.localFile  : article.missingImage}
               alt={article.Alt}
               css={cssSettings}
            >
               <span
                  className={[
                     classes.sansPro,
                     classes.pt20,
                     classes.bold,
                     classes.uppercase,
                     classes.opacity80,
                     classes.tagSpacing,
                  ].join(' ')}
               >
                  {article.tags.length > 0 && article.tags[0].Name}
               </span>
            </ImageOverlay>{' '}
         </Link>
         <div
            className={[
               classes.sansProBold,
               classes.pt18,
               classes.bold,
               classes.titleSpacing,
            ].join(' ')}
         >
            {article.Title}
         </div>
         <div
            className={[classes.openSans, classes.pt16, classes.italic].join(
               ' '
            )}
            style={{ display: 'flex', alignItems: 'center' }}
         >
            <AccessTime style={{ fontSize: '12px' }} />
            &nbsp;
            {article.Publish}
         </div>
      </div>
   )
}

export default SmallerNewsPanel
