import React, { useEffect } from 'react'
import { IconButton, Dialog } from '@material-ui/core'

import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'

const styles = theme => ({
   ...sharedStyles(theme),
   root: {
      display: 'grid',
      gridTemplateColumns: '1fr max-content ',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing(1),
      alignItems: 'center',
   },
   closeButton: {
      color: theme.palette.grey[500],
      justifySelf: 'end',
      position: 'absolute',
      top: '10px',
      right: '20px',
      [theme.breakpoints.down('sm')]: {
         top: '10px',
         right: '10px',
      },
   },
   title: {
      whiteSpace: 'nowrap',
      justifySelf: 'start',
   },
})

const DialogContent = withStyles(theme => ({
   root: {
      padding: theme.spacing(0),
   },
}))(MuiDialogContent)

const PeopleModal = withStyles(styles)(props => {
   const { isOpen, handleClose, children, classes } = props

   return (
      <Dialog
         onClose={handleClose}
         aria-labelledby="customized-dialog-title"
         open={isOpen}
         maxWidth="lg"
      >
         <CloseIcon onClick={handleClose} className={classes.closeButton} />
         <DialogContent dividers>{children}</DialogContent>
      </Dialog>
   )
})

export default PeopleModal
