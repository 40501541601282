import React from 'react'
import { Link } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import sharedStyles from '../styles/global'

function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box p={3}>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   headerSpacing: {
      margin: '3rem 0rem 0.3rem 0rem',
   },
   tabControl: {
      whiteSpace: 'nowrap',
   },
   nonActiveTab: {
      color: '#1D3787',
      opacity: '0.6',
   },
}))

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}

const MediaTabs = ({ selected }) => {
   const theme = useTheme()
   const css = useStyles(theme)
   const tabs = [
      { name: 'Articles', link: '/news-media' },
      { name: 'Videos', link: '/video' },
      { name: 'Gallery', link: '/gallery' },
      { name: 'Publications', link: '/publication' },
   ]

   return (
      <div>
         <Tabs
            value={selected}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
         >
            {tabs.map((tab, idx) => {
               let tabStyles = [
                  css.tabControl,
                  css.sansProSemiBold,
                  css.uppercase,
               ].join(' ')
               if (idx != selected)
                  tabStyles = [
                     css.tabControl,
                     css.sansProSemiBold,
                     css.nonActiveTab,
                     css.uppercase,
                  ].join(' ')
               return (
                  <Tab
                     label={tab.name}
                     key={`${tab.name}-tab-${idx}`}
                     {...a11yProps(idx)}
                     className={tabStyles}
                     component={Link}
                     to={tab.link}
                  />
               )
            })}
         </Tabs>
      </div>
   )
}
export default MediaTabs
