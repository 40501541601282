import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import Highlight from '../components/highlight'
import Carousel from 'nuka-carousel'
import PagingDots from './PagingDots'
import sharedStyles from '../styles/global'
import MissingImage from './missingImage'
import { useStaticQuery, graphql } from 'gatsby'
import ImageOverlay from './imageOverlay'
import { isIE } from '../services/utilityService'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   highlightsContainer: {
      width: '90%',
      margin: '0 auto',
      display: '-ms-grid',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      gridTemplateAreas: 'a b c d e',
      msGridColumns: '1fr 1fr 1fr 1fr 1fr',
      gridGap: '10px',
      alignItems: 'center',
      paddingTop: '5px',
   },
   ieGridGap: {
      marginRight: '10px',
   },
   highlightSlider: {
      display: 'none',
      maxHeight: '500px',
      [theme.breakpoints.down('sm')]: {
         display: 'block',
         height: '250px',
      },
   },
   highlightImages: {
      marginTop: '.5rem',
      [theme.breakpoints.down('sm')]: {
         display: 'none',
      },
   },
   slider: {
      color: 'white',
      paddingLeft: '15px',
      paddingRight: '15px',
      '& a': {
         color: 'white',
      },
   },
   whiteLinkColor: { color: '#ffffff' },
   tagSpacing: {
      paddingBottom: '0.8rem',
   },
   headerSpacing: {
      paddingBottom: '0rem',
      marginBottom: '0.3rem',
   },
   headerMobile: {
      [theme.breakpoints.down('sm')]: {
         fontSize: '1.4rem',
      },
   },
   mainContainer: {
      position: 'relative',
      margin: '0 auto',
      height: '400px',
      maxWidth: '1200px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundPosition: 'bottom',
   },
   spectrum: {
      width: '100%',
      display: 'block',
   },
   readMoreCarouselSpacing: {
      paddingLeft: `1rem`,
      marginBottom: '1.6rem',
   },
   fullSizeReadMore: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
         display: 'block',
      },
   },
}))

const SpotLightText = props => {
   var { highlight, css } = props
   return (
      <>
         {highlight.Title && (
            <h1
               className={[
                  css.headerMobile,
                  css.sansPro,
                  css.headerSpacing,
               ].join(' ')}
            >
               {highlight.Title.trim() == '' ? (
                  <div>
                     <br />
                     <br />
                  </div>
               ) : (
                  highlight.Title
               )}
            </h1>
         )}
         <a
            href={highlight.Link}
            className={[
               css.pt22,
               css.bold,
               css.whiteLinkColor,
               css.uppercase,
               css.fullSizeReadMore,
            ].join(' ')}
         >
            Read more
         </a>
      </>
   )
}

const SpotLight = props => {
   const theme = useTheme()
   const css = useStyles(theme)
   let highlights = props.data
   const gridTemplateAreaValues = ['a', 'b', 'c', 'd', 'e']
   const [activeHighlight, setActiveHighlight] = React.useState(0)
   const [isSlideShowActive, setSlideShowActive] = React.useState(true)
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))
   let selectedHighlight = highlights[activeHighlight]
   selectedHighlight.selected = true

   //-- Assume IE for initial load
   let highlightCss = [css.ieGridGap]
   if (typeof window !== 'undefined') {
      if (isIE() === false) highlightCss = []
   }

   function handleHighlight(event, value) {
      setActiveHighlight(value)
      stopSlideShow()
      highlights = highlights.map((highlight, index) => {
         if (index === parseInt(value)) {
            highlight.selected = true
         } else {
            highlight.selected = false
         }
         return highlight
      })
   }

   const data = useStaticQuery(graphql`
      query {
         spectrum: file(ext: { eq: ".png" }, name: { eq: "spectrum" }) {
            id
            name
            publicURL
            childImageSharp {
               fluid {
                  src
               }
            }
         }
      }
   `)
   const t = setTimeout(nextSlide, 7000)

   function stopSlideShow() {
      setSlideShowActive(false)
      clearTimeout(t)
   }

   function startSlideShow() {
      setSlideShowActive(true)
   }

   function nextSlide() {
      //-- only automate slides on non-mobile screens
      if (!isMobile) {
         if (isSlideShowActive) {
            selectedHighlight.selected = false
            let nextIndex = (activeHighlight + 1) % highlights.length
            setActiveHighlight(nextIndex)
         } else {
            //-- restart the slide show after 30 seconds
            setTimeout(startSlideShow, 30000)
         }
      }
   }

   const selectedHighlightCss = {
      crop: true,
      image: {
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
      },
      container: {
         height: `400px`,
      },
      overlay: {
         background:
            'linear-gradient(to top,  rgba(12,29,82,0.9) 10%,transparent  )',
      },
      text: {
         top: '85%',
      },
   }
   if (selectedHighlight.Gradient === false) {
      selectedHighlightCss.overlay.background = ''
   }
   return (
      <div>
         <div className={css.highlightSlider}>
            <Carousel
               height="255px"
               heightMode="current"
               slideIndex={parseInt(activeHighlight)}
               swiping={true}
               transitionMode={`scroll`}
               wrapAround={true}
               afterSlide={slideIndex => setActiveHighlight(slideIndex)}
               renderCenterLeftControls={() => (
                  <div className={css.slider}>
                     <SpotLightText highlight={selectedHighlight} css={css} />
                  </div>
               )}
               renderBottomLeftControls={props => (
                  <div className={css.readMoreCarouselSpacing}>
                     <a
                        href={selectedHighlight.Link}
                        className={[
                           css.pt22,
                           css.bold,
                           css.whiteLinkColor,
                           css.uppercase,
                        ].join(' ')}
                     >
                        Read more
                     </a>{' '}
                  </div>
               )}
               renderBottomCenterControls={() => {}}
               renderCenterRightControls={({ nextSlide }) => ''}
               renderBottomRightControls={props => <PagingDots {...props} />}
            >
               {highlights.map((highlight, index) => {
                  if (highlight.Image.localFile) {
                     const imageCss = {
                        container: { height: '250px' },
                        image: { height: '250px' },
                     }
                     if (highlight.Gradient === false) {
                        imageCss.overlay = { background: '' }
                     }
                     return (
                        <ImageOverlay
                           image={highlight.Image.localFile}
                           alt={highlight.Name}
                           key={'SliderImage_' + index}
                           css={imageCss}
                        />
                     )
                  } else {
                     return <MissingImage />
                  }
               })}
            </Carousel>
         </div>
         <div className={[css.mainContainer, css.highlightImages].join(' ')}>
            <ImageOverlay
               image={selectedHighlight.Image.localFile}
               alt={selectedHighlight.Alt}
               css={selectedHighlightCss}
            >
               <div>
                  <div>
                     <SpotLightText highlight={selectedHighlight} css={css} />
                  </div>
               </div>
            </ImageOverlay>
            <div>
               <img src={data.spectrum.publicURL} className={css.spectrum} />
            </div>
         </div>
         <div
            className={[css.highlightsContainer, css.highlightImages].join(' ')}
         >
            {highlights.map((highlight, index) => {
               const gridColumn = index + 1
               const gridAreaValue = `'${gridTemplateAreaValues[index]}'`
               return (
                  <div
                     className={highlightCss.join(' ')}
                     onClick={e => handleHighlight(e, `${index}`)}
                     key={'highlightImage_' + index}
                     style={{
                        msGridColumn: gridColumn,
                        gridArea: gridAreaValue,
                     }}
                  >
                     <Highlight data={highlight} />
                  </div>
               )
            })}
         </div>
      </div>
   )
}

export default SpotLight
