import React from 'react'
import { Button, Grid, Container, MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,  CircularProgress, Checkbox} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import axios from 'axios'
import sharedStyles from '../styles/global'
import CssTextField from '../components/cssTextField'
import Modal from '../components/modal'
import Alert from "@material-ui/lab/Alert"
import Helmet from 'react-helmet'
import Recaptcha from 'react-recaptcha';

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   form: {
      width: '100%', // Fix IE 11 issue.
   }
}))

export default function ContactUs() {
   function validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      const isValid = re.test(email.target.value);
      isValid ? setShowMsg(false) : setShowMsg(true)
   }
   const handleClose = (event, reason) => {
      if (reason === "clickaway") {
         return
      }

      setOpen(false)
   }
   const [openIsLoading, setOpenIsLoading] = React.useState(false)
   const handleCloseIsLoading = () => {
     setOpenIsLoading(false)
   }
   const [OpenisError, setOpenIsError] = React.useState(false)
   const handleCloseIsError = () => {
     setOpenIsError(false)
   }
   const [open, setOpen] = React.useState(false)
   const [showMsg, setShowMsg] = React.useState(false)
   const [userMsg, setUserMsg] = React.useState('');
   const theme = useTheme()
   const classes = useStyles(theme)
   const [category, SetCategory] = React.useState('General');
   const [OrgType, SetOrgType] = React.useState('');
   const [isCapchaLoaded, setIsCapchaLoaded] = React.useState(false);
   const [isVerified, setIsVerified] = React.useState(false);
   const [SessionBool, SetSessionBool]= React.useState(false);
   const [successMsg, setSuccessMsg] = React.useState('');

   const recaptchaLoaded = () => {
      setIsCapchaLoaded(true)
   }
   const expCallBack = () => {
      setIsVerified(false)
   }
   const verifyCallback = () => {
      setIsVerified(true)
   }


   const handleSubmit = event => {
      event.preventDefault();
      const form = new FormData(event.target)
      const firstName = form.get('firstName')
      const lastName = form.get('lastName')
      const name = `${firstName} ${lastName}`;
      setUserMsg('Thank you ' + name)
      const organization = form.get('organization');
      const OrganizationType = form.get('OrganizationType');
      const email = form.get('email')
      const message = form.get('message')
      if (showMsg || !isCapchaLoaded || !isVerified || (category == 'Industry' && OrgType == '')) {
        return
      }
      setOpenIsLoading(true)
  
      const current = new Date();  
      const timeStamp = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
      let url, payload;

      setSuccessMsg('Thank you, ' + name + '. Your form has been successfully submitted.');

  
      if(category == 'Partnership'){
         payload = {
             firstName,
             lastName,
             email,
             phone: form.get('Phone'),
             institutionName: form.get('organization'),
             institutionType: OrganizationType,
             reasoning: message,
             session: SessionBool,
             createdDt: timeStamp
         };
         url = '/api/CIS/CISRequest';
      } else {
         payload = {
             name: `${firstName} ${lastName}`,
             message,
             email,
             organization
         };
         url = '/api/ContactUs/SendEmailContactUs';
      }

  
      axios.post(url, payload)
           .then(response => {
               // Handle success
               setOpenIsLoading(false)
               setOpen(true)
            })
           .catch(error => {
               setOpenIsLoading(false)
               setOpenIsError(true)
           });
  
    
      // event.target.reset();
  }
  
   const [showRecaptcha, setShowRecaptcha] = React.useState(false);

   React.useEffect(() => {
      const timer = setTimeout(() => {
         setShowRecaptcha(true);
      }, 500); // Adjust the delay as needed
   
      return () => {
         clearTimeout(timer);
         setShowRecaptcha(false);
      };
   }, []);

   return (
      <Container component="main" maxWidth="md">
          <Helmet>
            <script src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`} async defer></script>
         </Helmet>
         <form className={classes.form} onSubmit={handleSubmit} id="ContactUsform">
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <CssTextField
                     autoComplete="fname"
                     name="firstName"
                     variant="outlined"
                     required
                     fullWidth
                     id="firstName"
                     label="First Name"
                     autoFocus
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <CssTextField
                     variant="outlined"
                     required
                     fullWidth
                     id="lastName"
                     label="Last Name"
                     name="lastName"
                     autoComplete="lname"
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <CssTextField
                     variant="outlined"
                     required
                     fullWidth
                     id="organization"
                     label="Organization Name"
                     name="organization"
                     autoComplete="organization"
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <CssTextField
                     variant="outlined"
                     required
                     fullWidth
                     id="email"
                     label="Email Address"
                     name="email"
                     autoComplete="email"
                     onChange={(e) => { validateEmail(e) }}
                  />
                  <Alert severity="warning" style={{ display: showMsg ? 'block' : 'none', color: 'red' }} icon={false}>Email is not valid!</Alert>
               </Grid>
               <Grid item xs={12}>
                  <FormControl component="fieldset">
                     <FormLabel component="legend">Type of Inquiry</FormLabel>
                     <RadioGroup row aria-label="position" name="position" defaultValue="top"  onChange={e => SetCategory(e.target.value)}>
                        <FormControlLabel value="General" id="General" control={<Radio color="primary" checked={category == 'General' ? true : false}  label="General" />} label="General" />
                        <FormControlLabel value="Partnership" id="Partnership"   control={<Radio color="primary" />} label="Partnership" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               {category == "Partnership" && <>
                  <Grid item xs={12} sm={6}>
                     <CssTextField
                        variant="outlined"
                        fullWidth
                        id="Phone"
                        label="Phone"
                        name="Phone"
                        autoComplete="Phone"
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <CssTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="OrganizationType"
                        label="Organization Type"
                        name="OrganizationType"
                        autoComplete="OrganizationType"
                        select
                        value={OrgType}
                        onChange={e=>{SetOrgType(e.target.value)}}
                        ><MenuItem
                              key={'Industry'}
                              value={'Industry'}
                              style={{ whiteSpace: 'normal' }}
                           >
                           Industry
                        </MenuItem>
                        <MenuItem
                                 key={'Government'}
                                 value={'Government'}
                                 style={{ whiteSpace: 'normal' }}
                              >
                           Government
                        </MenuItem>
                        <MenuItem
                                 key={'Academia'}
                                 value={'Academia'}
                                 style={{ whiteSpace: 'normal' }}
                              >
                           Academia
                        </MenuItem>
                        <MenuItem
                                 key={'International'}
                                 value={'International'}
                                 style={{ whiteSpace: 'normal' }}
                              >
                           International
                        </MenuItem>    
                     </CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl component="fieldset">
                        <FormControlLabel  control={<Checkbox color="primary" id="Session" name ="Session" value={SessionBool} onChange={e => SetSessionBool(e.target.checked)}/>} label="Would you like to schedule an industry session with Commercialization, Innovation, and Synergies (CIS) representatives to discuss the request and learn more about CIS?" />
                     </FormControl>
                  </Grid>
               </>}
               <Grid item xs={12}>
                  <CssTextField
                     variant="outlined"
                     required
                     fullWidth
                     id="message"
                     label="Message"
                     name="message"
                     multiline
                     rows={6}
                  />
               </Grid>
            </Grid>
            {showRecaptcha && (
                <Grid container spacing={2}>
                       <Grid item xs={12} sm={6} style={{ marginTop: 5 }}>
                  <Recaptcha
                     sitekey="6LfFxL4ZAAAAAGeU0rcZwrwD7InxYOu7kUx1AK-H"
                     render="explicit"
                     verifyCallback={verifyCallback}
                     onloadCallback={recaptchaLoaded}
                     expiredCallback={expCallBack}
                     id="recaptcha-contactUs"
                  />
               </Grid>
                </Grid>
            )}
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                  > Send Message</Button>
               </Grid>
            </Grid>
         </form>
         <Modal title={userMsg} isOpen={open} handleClose={handleClose}>
            <p>We have recieved your message</p>
         </Modal>
         <Modal title={successMsg} isOpen={open} handleClose={handleClose}>
        <h4>Your form has been successfully submitted.</h4>
        {/* <h4 style={{ marginTop: 5 }}> Please expect a response {valUrgencySub}.</h4> */}
      </Modal>
      <Modal
        // title={'Submitting please wait'}
        isOpen={openIsLoading}
        handleClose={handleCloseIsLoading}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          color: '#333',
          fontFamily: '"Helvetica Neue", sans-serif',
        }}
      >
        <h2 style={{ margin: '0 0 20px 0' }}>Submitting, please wait...</h2>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Modal>

      <Modal isOpen={OpenisError} handleClose={handleCloseIsError}>
        <h4>Something went wrong, please try again later.</h4>
      </Modal>
      </Container>
   )
}
