import React from 'react'
import TextBlock from '../news/textBlock'

const HeaderOnly = ({ css, article }) => {
   return (
      <>
         <TextBlock
            subHeading={article.SubHeading1}
            text={article.Text1.data.Text1}
         />
         <TextBlock
            subHeading={article.SubHeading2}
            text={article.Text2.data.Text2}
         />
         <TextBlock
            subHeading={article.SubHeading3}
            text={article.Text3.data.Text3}
         />
         <TextBlock
            subHeading={article.SubHeading4}
            text={article.Text4.data.Text4}
         />
         <TextBlock
            subHeading={article.SubHeading5}
            text={article.Text5.data.Text5}
         />
      </>
   )
}

export default HeaderOnly
