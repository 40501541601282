import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../styles/global'
import hexToRgba from 'hex-to-rgba'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   factCard: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: '6px',
      transition: 'transform 0.1s linear',
      '&:hover': {
         transform: 'scale(1.03)',
         // TODO:
         // get the slide-up transition on the overlay
         // triggered from the parent container hover
         // (since you can't hover over something off-screen)
         // see this pen for an example:
         // https://codepen.io/dylancristy/pen/GRKeRvo
         //
         // '.textOverlay': {
         //     transform: 'translateY(0%)',
         // }
         //
         // TODO:
         // also add a small drop-shadow to
         // give the appearance of "elevation"
         // when the card is hovered/active
      },
   },
   imageContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
   },
   textOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '1.5rem 1.2rem',
      backgroundColor: 'rgba(62, 99, 211, 0.7)', // #3E63D3 (Lighter Blue) with 70% opacity
      color: 'white',
      fontFamily: 'Source Sans Pro',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      // TODO:
      // change the transition from opacity
      // to sliding up from bottom
      // transform: 'translateY(100%)',
      // transition: 'transform 0.1s ease',
      transition: 'opacity 0.1s linear',
      opacity: '0',
      '&:hover': {
         opacity: '1',
      },
   },
   factTitle: {
      fontWeight: '600',
      marginBottom: '0.7rem',
   },
   factText: {
      fontSize: '0.95rem',
   },
}))

const QuickFactPanel = props => {
   const { fact, msGrid, color } = props

   const rgba = color ? hexToRgba(color, 0.7) : 'rgba(62, 99, 211, 0.7)'
   const theme = useTheme()
   const classes = useStyles(theme)

   const defaultImg = useStaticQuery(graphql`
      query {
         file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
            childImageSharp {
               fixed(cropFocus: CENTER, width: 600) {
                  ...GatsbyImageSharpFixed
               }
            }
         }
      }
   `)

   let showImage = defaultImg.file.childImageSharp.fixed
   if (
      fact.Image.localFile.horizontal &&
      fact.Image.localFile.horizontal.fixed
   ) {
      showImage = fact.Image.localFile.horizontal.fixed
      const aspectRatio = fact.Image.localFile.horizontal.fixed.aspectRatio
      if (aspectRatio <= 1) showImage = fact.Image.localFile.vertical.fixed
   }

   return (
      <div
         className={classes.factCard}
         style={{
            msGridColumn: msGrid.col,
            msGridRow: msGrid.row,
            msGridRowAlign: 'center',
            msGridColumnAlign: 'center',
         }}
      >
         <div className={classes.imageContainer}>
            <Img
               fixed={showImage}
               className={classes.cardImage}
               alt={fact.Alt}
            />
         </div>
         <div className={classes.textOverlay} style={{ backgroundColor: rgba }}>
            <div className={[classes.factTitle, classes.pt22].join(' ')}>
               {fact.Alt}
            </div>
            <div className={classes.factText}>
               {fact.Content}
               {fact.Credit && (
                  <span
                     className={[classes.sansProSemiBold, classes.italic].join(
                        ' '
                     )}
                  >
                     &nbsp; Credit: {fact.Credit}
                  </span>
               )}
            </div>
         </div>
      </div>
   )
}

export default QuickFactPanel
