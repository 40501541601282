import { Link, useStaticQuery, graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import { makeStyles, useTheme, useScrollTrigger } from '@material-ui/core'
import { Button, AppBar, Toolbar } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import SearchBox from './searchBox'
import sharedStyles from '../styles/global'

function ElevationScroll(props) {
   const { children, window } = props
   const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
   })

   return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
   })
}

ElevationScroll.propTypes = {
   children: PropTypes.element.isRequired,
   window: PropTypes.func,
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   appBar: {
      backgroundColor: 'white',
   },
   toolBar: {
      // make width match global .content class
      width: '1000px',
      [theme.breakpoints.down(1000)]: {
         width: '100%',
      },
      margin: 'auto',
      display: '-ms-grid',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      msGridColumns: '1fr 1fr 1fr',
      alignItems: 'center',
   },
   meatballLink: {
      msGridColumn: '1',
      justifySelf: 'start',
      msGridColumnAlign: 'start',
      msGridRowAlign: 'center',
   },
   nasaLogo: {
      height: '70px',
      width: '70px',
   },
   spectrum: {
      width: '100%',
      padding: '0px',
      margin: '0px',
   },
   escLogoLink: {
      msGridColumn: '2',
      justifySelf: 'center',
      msGridColumnAlign: 'center',
      msGridRowAlign: 'center',
   },
   toolbarSearchGroup: {
      justifySelf: 'end',
      msGridColumn: '3',
      msGridColumnAlign: 'center',
      msGridRowAlign: 'center',
   },
   navigation: {
      backgroundColor: '#0c1d52',
      color: '#fff',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
         display: 'none',
         flexDirection: 'column',
      },
   },
   navButton: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
         marginLeft: theme.spacing(1),
         marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('640')]: {
         marginLeft: 0,
         marginRight: 0,
      },
   },
   searchBoxDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
         display: 'flex',
      },
      '& input': {
         marginRight: theme.spacing(1),
      },
   },
   mobileControls: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
         display: 'none',
      },
   },
   searchBoxMobile: {
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      padding: '5px',
      [theme.breakpoints.up('sm')]: {
         display: 'none',
      },
   },
   searchIcon: {
      verticalAlign: 'top',
      position: 'relative',
      color: '#0c1d52',
      fontSize: '20pt',
   },
   searchBox: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
         display: 'flex',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
   menuIcon: {
      color: '#0c1d52',
   },
   rainbowBar: {
      height: '8px',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
   },
}))

const Header = ({ siteTitle }) => {
   const hideshow = function hideshow(id) {
      var obj = document.getElementById(id)
      if (obj.style.display === 'flex') obj.style.display = 'none'
      else obj.style.display = 'flex'
   }
   const goto = function (event, name) {
      navigate(`/${name}`)
   }
   const menu = [
      { name: 'Home', slug: '' },
      { name: 'About', slug: 'about' },
      { name: 'People', slug: 'people' },
      { name: 'Projects', slug: 'projects' },
      { name: 'Partnerships', slug: 'partnerships' },
      { name: 'Obtain Services', slug: 'services' },
      { name: 'News & Multimedia', slug: 'news-media' },
      { name: 'Participate', slug: 'participate' },
   ]

   const theme = useTheme()
   const classes = useStyles(theme)
   const data = useStaticQuery(graphql`
      query {
         nasaLogo: file(relativePath: { eq: "nasa-logo.svg" }) {
            name
            publicURL
         }
         spectrum: file(relativePath: { eq: "spectrum.png" }) {
            name
            publicURL
         }
         esc: file(relativePath: { eq: "ESC_Logo_Horizontal.png" }) {
            childImageSharp {
               fixed(height: 40) {
                  ...GatsbyImageSharpFixed
               }
            }
         }
      }
   `)
   const openSearch = function () {
      const query = document.querySelector('#searchBoxDesktop > input').value
      if (query) {
         // NOTE: if changing the method by which the search query is passed
         // to the search page, it also needs to be changed in the SearchBox component

         // use this if we want to use gatsby internals to pass the query
         // navigate(`/search`, { state: { query: query } })

         // use this if we want to pass the query using URL query params (linkable)
         const queryString = `query=${encodeURIComponent(query)}`
         navigate(`/search?${queryString}`)
      }
   }

   return (
      <div>
         <ElevationScroll>
            <AppBar className={classes.appBar}>
               <Toolbar className={classes.toolBar}>
                  <div className={classes.meatballLink}>
                     <a href="https://www.nasa.gov/" target="_blank">
                        <img
                           src={data.nasaLogo.publicURL}
                           alt="NASA Logo"
                           className={classes.nasaLogo}
                        />
                     </a>
                  </div>
                  <div className={classes.escLogoLink}>
                     <Link to="/">
                        <Img fixed={data.esc.childImageSharp.fixed} />
                     </Link>
                  </div>
                  <div className={classes.toolbarSearchGroup}>
                     <div
                        className={classes.searchBoxDesktop}
                        id="searchBoxDesktop"
                     >
                        <SearchBox />
                        <SearchIcon
                           className={[
                              classes.searchIcon,
                              classes.pointer,
                           ].join(' ')}
                           onClick={e => openSearch()}
                        />
                     </div>
                     <div className={classes.mobileControls}>
                        <SearchIcon
                           id="searchIcon"
                           className={classes.searchIcon}
                           onClick={e => hideshow('searchBox')}
                        />
                        <Button
                           className={classes.menuIcon}
                           color="inherit"
                           onClick={e => hideshow('menu')}
                        >
                           <MenuIcon />
                        </Button>
                     </div>
                  </div>
               </Toolbar>

               <div className={classes.searchBoxMobile}>
                  <div id="searchBox" className={classes.searchBox}>
                     <SearchBox />
                  </div>
               </div>

               <img
                  src={data.spectrum.publicURL}
                  className={classes.spectrum}
               />

               <nav id="menu" className={classes.navigation}>
                  {menu.map(option => (
                     <Button
                        className={[
                           classes.navButton,
                           classes.sansProBold,
                        ].join(' ')}
                        color="inherit"
                        onClick={e => goto(e, `${option.slug}`)}
                        key={option.slug}
                     >
                        {option.name}
                     </Button>
                  ))}
               </nav>
            </AppBar>
         </ElevationScroll>
      </div>
   )
}

Header.propTypes = {
   siteTitle: PropTypes.string,
}

Header.defaultProps = {
   siteTitle: ``,
}

export default Header
