import React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
   Box,
   Tab,
   Tabs,
   Grid,
   Typography,
   ExpansionPanel,
   ExpansionPanelSummary,
   ExpansionPanelDetails,
   useMediaQuery,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Markdown from 'react-markdown/with-html'
import sharedStyles from '../styles/global'
import Layout from '../components/layout'
import ContactUs from '../components/contactUs'
import Contact from '../components/contact'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'
import {
   getQueryStringParams,
   extractQueryParam,
   determineActiveTab,
   determineInitialTab,
} from '../services/utilityService'

function getQueryVariable(variable) {
   if (typeof window !== 'undefined') {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
         var pair = vars[i].split('=')
         if (pair[0] == variable) {
            return pair[1]
         }
      }
   }
   return false
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   servicesPageHeader: {
      margin: '3rem 0rem 0.3rem 0rem',
   },
   tabControls: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
         marginBottom: theme.spacing(4),
      },
   },
   serviceHeader: {
      margin: theme.spacing(3, 0, 1, 0),
      [theme.breakpoints.up('md')]: {
         margin: theme.spacing(4, 0, 2, 0),
      },
   },
   panelRoot: {
      boxShadow: 'none',
      borderBottom: 'solid 1px #e4e4e4',
   },
   servicePanelContent: {
      paddingRight: theme.spacing(4),
      '& p': {
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
         paddingRight: theme.spacing(6),
      },
   },
   contactStyling: {
      marginBottom: theme.spacing(2),
   },
}))

function TabPanel(props) {
   const { children, value, index, ...other } = props
   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}

function expandPanel(panel) {
   const panelName = getQueryVariable('panel')
   var rtn = false
   panel == panelName ? (rtn = true) : (rtn = false)
   return rtn
}

const OurServices = ({ data, location }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   function handleTabChange(evt, newTabIndex) {
      setActiveTab(newTabIndex)

      const newTabParamValue = tabNames[newTabIndex].toLowerCase()
      if (typeof window !== 'undefined') {
         const newURL = `${window.location.origin}${
            window.location.pathname
         }?tab=${encodeURIComponent(newTabParamValue)}`
         window.history.pushState(
            { path: newURL, tabIndex: newTabIndex },
            null,
            newURL
         )
      }
      setTabParam(newTabParamValue)
   }

   if (typeof window !== 'undefined') {
      window.addEventListener('popstate', function(data) {
         const state = data.state
         if (state && state.tabIndex >= 0) setActiveTab(data.state.tabIndex)
         else setActiveTab(0)
      })
   }

   const serviceInfo = data.allStrapiService
   const serviceAreas = serviceInfo.distinct
   const allServices = serviceInfo.nodes

   const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
   const serviceHeaderStyles = [
      css.uppercase,
      css.sansProSemiBold,
      css.serviceHeader,
      isLargeScreen ? css.pt40 : css.pt30,
   ].join(' ')
   const serviceSubHeaderStyles = [
      css.bold,
      isLargeScreen ? css.pt26 : css.pt20,
   ].join(' ')
   const servicePanelContentStyles = [
      css.servicePanelContent,
      isLargeScreen ? css.pt22 : css.pt18,
   ].join(' ')

   const tabNames = ['EXPLORE SERVICES', 'CONTACT US']

   const [tabParam, setTabParam] = useState('')
   const queryParams = getQueryStringParams(location.href)
   const [activeTab, setActiveTab] = useState(() =>
      determineInitialTab(location.href, tabNames)
   )
   useEffect(() => {
      determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
   }, [tabParam])

   return (
      <Layout>
         <SEO title="Other Services" />
         <Helmet>
            <meta charSet="utf-8" />
         </Helmet>
         <ImageHeader
            title={`Other Services`}
            image={data.headerImage}
         ></ImageHeader>
         <div id="content" className={css.content}>
            <div className={css.tabControls}>
               <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
               >
                  {tabNames.map((tabName, idx) => {
                     const tabStyles = [css.tabControl, css.sansProSemiBold]
                     if (idx !== activeTab) {
                        tabStyles.push(css.nonActiveTab)
                     }
                     const tabClasses = tabStyles.join(' ')
                     return (
                        <Tab
                           label={tabName}
                           key={`${tabName}-tab-${idx}`}
                           {...a11yProps(idx)}
                           className={tabClasses}
                        />
                     )
                  })}
               </Tabs>
            </div>
            <TabPanel value={activeTab} index={0}>
               {serviceAreas.map(serviceArea => {
                  const services = allServices.filter(
                     src => src.service === serviceArea
                  )
                  const [expanded, setExpanded] = React.useState(false)

                  const handleChange = panel => (event, newExpanded) => {
                     setExpanded(newExpanded ? panel : false)
                  }

                  const serviceDetails = services.map((srv, idx) => (
                     <div key={`${srv.Title}-${idx}`}>
                        <ExpansionPanel
                           className={css.panelRoot}
                           defaultExpanded={expandPanel(
                              `${serviceArea
                                 .toLowerCase()
                                 .replace(/\s/g, '-')}-panel-${idx}`
                           )}
                           onChange={handleChange(
                              `${serviceArea
                                 .toLowerCase()
                                 .replace(/\s/g, '-')}-panel-${idx}`
                           )}
                        >
                           <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${serviceArea
                                 .toLowerCase()
                                 .replace(/\s/g, '-')}-panel-${idx}`}
                              id={`${serviceArea
                                 .toLowerCase()
                                 .replace(/\s/g, '-')}-panel-${idx}`}
                           >
                              <div className={serviceSubHeaderStyles}>
                                 {srv.Title}
                              </div>
                           </ExpansionPanelSummary>
                           <ExpansionPanelDetails>
                              <div className={servicePanelContentStyles}>
                                 <Markdown
                                    source={
                                       srv.FormattedText.data.FormattedText
                                    }
                                    escapeHtml={false}
                                 />
                                 {/* COMMENTED TO REMOVE CONTECT INFORMATION BY REQUEST FROM DAVID ON 1/11/2022
                                  {srv.contacts.map((contact,ind) => (
                                    <span className={css.contactStyling} key={ind}>
                                       <Contact info={contact}></Contact>
                                    </span>
                                 ))} */}
                              </div>
                           </ExpansionPanelDetails>
                        </ExpansionPanel>
                     </div>
                  ))
                  return (
                     <div key={serviceArea}>
                        <p className={serviceHeaderStyles}>
                           Companion Services
                        </p>
                        {serviceDetails}
                     </div>
                  )
               })}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
               <Grid item xs={12} sm={12} style={{ marginTop: 40 }}>
                  <p className={serviceHeaderStyles} style={{ marginLeft: 20 }}>
                     Contact Us
                  </p>
                  <ContactUs />
               </Grid>
            </TabPanel>
         </div>
      </Layout>
   )
}

export default WithLocation(OurServices)

export const OtherServiceQueryNew = graphql`
   query OtherServiceQueryNew {
      allStrapiService(
         filter: {
            Title: { ne: "Communications and Navigation Network Services" }
            service: { eq: "Communications Network Services" }
         }
         sort: { fields: [service, Position] }
      ) {
         distinct(field: service)
         nodes {
            service
            Position
            Title
            FormattedText {
               data {
                  FormattedText
               }
            }
            contacts {
               Name
               Title
               Email
               Facility
               Phone
            }
         }
      }
      headerImage: file(relativePath: { eq: "headers/mdas.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
