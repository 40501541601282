import React from 'react'
import sharedStyles from '../styles/global'
import { useTheme, makeStyles, Box, Link } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Markdown from 'react-markdown/with-html'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   image: settings => ({ ...settings.imageCss }),
   container: {
      width: '900px',
      display: '-ms-grid',
      display: 'grid',
      overflowX: 'hidden',
      //gridTemplateColumns: 'repeat( auto-fill, minmax( 200px, 1fr ) )',
      gridAutoRows: 'auto',
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr',
         msGridColumns: '1fr',
         maxWidth: '300px',
      },
      [theme.breakpoints.up('sm')]: {
         gridTemplateColumns: '1fr 1fr',
         msGridColumns: '1fr 1fr',
      },
      [theme.breakpoints.up('md')]: {
         gridTemplateColumns: '1fr 1fr 1fr',
         msGridColumns: '1fr 1fr 1fr',
      },
   },
   leftHalf: {
      width: '200px',
      height: '450px',
      overflow: 'auto',
      backgroundColor: '#f0f3fb',
      [theme.breakpoints.down('sm')]: {
         display: 'none',
         visibility: 'hidden',
      },
   },

   middleHalf: {
      width: '250px',
      [theme.breakpoints.down('sm')]: {
         width: '300px',
         textAlign: 'center',
      },
   },

   rightHalf: {
      width: '450px',
      height: '400px',
      overflow: 'auto',
      padding: '20px 40px 0 0',
      overflow: 'auto',
      margin: '20px 0 0 0',
      [theme.breakpoints.down('sm')]: {
         width: '300px',
         height: '300px',
         padding: '20px',
         overflow: 'auto',
      },
   },
   imgPhoto: {
      maxHeight: '270px',
      maxWidth: '230px',
      padding: '20px',
   },
   divider: {
      borderBottom: '1px solid  #bac3dd',
   },
}))

const peopleCards = props => {
   const theme = useTheme()
   const classes = useStyles(theme)
   const { allPeople, selectedPerson } = props
   const [selPerson, setselPerson] = React.useState(selectedPerson)
   const [selectedAllPerson, setselectedAllPerson] = React.useState(allPeople)
   const [loaded, setLoaded] = React.useState(false)

   const handleLeaderClick = leader => {
      setselPerson(leader)
      const arr = [...allPeople]
      const newArr = arr.filter(el => el.id != leader.id)
      setselectedAllPerson(newArr)
      setLoaded(true)
   }

   const onLoadFilterCurrent = leader => {
      if (!loaded) {
         setselPerson(leader)
         const arr = [...allPeople]
         const newArr = arr.filter(el => el.id != leader.id)
         setselectedAllPerson(newArr)
         setLoaded(true)
      }
   }

   return (
      <div
         className={classes.container}
         onLoad={() => onLoadFilterCurrent(selectedPerson)}
      >
         <div className={classes.leftHalf}>
            <List key={`list`}>
               {selectedAllPerson.map((item, index) => (
                  <ListItem
                     className={classes.divider}
                     alignItems="flex-start"
                     button
                     key={`listitem-${index}`}
                     onClick={() => handleLeaderClick(item)}
                  >
                     <ListItemText
                        key={`listItemText-${index}`}
                        primary={item.Name}
                        secondary={item.Position}
                     />
                  </ListItem>
               ))}
            </List>
         </div>

         <div className={classes.middleHalf}>
            <img
               alt={selPerson.Name}
               src={selPerson.Image.localFile.childImageSharp.fluid.src}
               className={classes.imgPhoto}
            />
         </div>

         <div className={classes.rightHalf}>
            {selPerson.project ? (
               <h2>
                  <Box
                     style={{
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        backgroundColor: selPerson.project.Color,
                     }}
                  ></Box>
                  &nbsp;&nbsp;
                  {selPerson.Name}
               </h2>
            ) : (
               <h2>{selPerson.Name}</h2>
            )}

            <div>{selPerson.Position}</div>

            {selPerson.project && (
               <div>
                  <a href={`../projects/${selPerson.project.Acronym}`}>
                     {selPerson.project.Name} ({selPerson.project.Acronym})
                  </a>
               </div>
            )}

            <br />
            <div>
               Joined NASA: <strong>{selPerson.JoinedNasa}</strong>
            </div>
            <div>
               ESC Member since: <strong>{selPerson.JoinedESC}</strong>
            </div>
            <br />
            <Markdown
               source={selPerson.Biography.data.Biography.replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br />'
               ).replace(/(?:>>)/g, '>&nbsp;&nbsp;')}
               escapeHtml={false}
            />

            <br />
            <h3>Education</h3>
            <br />
            <Markdown
               source={selPerson.Education.data.Education.replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br />'
               ).replace(/(?:>>)/g, '>&nbsp;&nbsp;')}
               escapeHtml={false}
            />
         </div>
      </div>
   )
}

export default peopleCards
