import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import Carousel from 'nuka-carousel'
import PropTypes from 'prop-types'
import _ from 'lodash'
import sharedStyles from '../styles/global'
import ImageOverlay from './imageOverlay'
import PeopleCard from './peopleCard'
import PeopleModal from './peopleModal'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  carouselContainer: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '30px 1fr 1fr 1fr 1fr 1fr 30px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '30px 1fr 1fr 1fr 1fr 30px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '30px 1fr 1fr 30px',
    },
  },
  hideText: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
      display: 'none',
    },
  },
  controlStyling: {
    fontSize: '2.5rem',
    marginTop: '25%',
  },
}))

// -- helps calculate the window size
let defaultWidth
let defaultHeight
if (typeof window !== 'undefined') {
  defaultHeight = window.innerHeight
  defaultWidth = window.innerWidth
}
const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    windowHeight: defaultHeight,
    windowWidth: defaultWidth,
  })
  useEffect(() => {
    const handler = () =>
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return dimensions
}

const getNumPictures = (theme) => {
  let numPictures = 5
  const viewWidth = useWindowSize(1200).windowWidth
  if (viewWidth < theme.breakpoints.values.lg) {
    numPictures = 5
  }
  if (viewWidth < theme.breakpoints.values.md) {
    numPictures = 3
  }
  if (viewWidth < theme.breakpoints.values.sm) {
    numPictures = 2
  }
  return numPictures
}

const PeopleCarousel = (props) => {
  const { leaders } = props
  const theme = useTheme()
  const classes = useStyles(theme)

  const handleLeaderClick = (leader) => {
    setselectedPerson(leader)
    setOpen(true)
  }

  const [selectedPerson, setselectedPerson] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const numPictures = getNumPictures(theme)

  const ldrName = [classes.sansPro, classes.pt20, classes.bold].join(' ')
  const ldrPosition = [classes.sansPro, classes.pt18, classes.hideText].join(' ')
  const overlayCss = {
    container: {
      borderRadius: 10,
      '&:hover': {
        '& > div': {
          background: '#3E63D3 ',
          opacity: '0.5',
        },
        '& > span': {
          display: 'block',
        },
      },
    },

    overlay: {
      background: 'linear-gradient(to top, rgba(29, 55, 135, 0.9), rgba(0, 0, 0, 0))',
    },
    image: {
      height: '200px',
      [theme.breakpoints.down('sm')]: {
        height: '150px',
      },
    },
    text: {
      top: '75%',
      lineHeight: 1,
      display: 'block',
      paddingLeft: '10px',
    },
  }
  if (leaders.length === 0) return <> </>
  return (
    <Carousel
      renderBottomCenterControls={() => ''}
      renderCenterLeftControls={({ previousSlide }) => (
        <KeyboardArrowLeft className={classes.controlStyling} onClick={previousSlide} />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <KeyboardArrowRight className={classes.controlStyling} onClick={nextSlide} />
      )}
    >
      {_.chunk(leaders, numPictures).map((chunk, i) => (
        <div className={classes.carouselContainer} key={`chunk_${i}`}>
          <span />
          {chunk.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div key={index} onClick={() => handleLeaderClick(item)}>
              <ImageOverlay className={[classes.imageGradient].join(' ')} css={overlayCss} image={item.Image.localFile}>
                <font className={ldrName}>{item.Name}</font>
                <br />
                <font className={ldrPosition}>{item.Position}</font>
              </ImageOverlay>
            </div>
          ))}
          {/* ############################ MODAL POP UP ########################### */}
          {selectedPerson && (
            <PeopleModal handleClose={handleClose} isOpen={open}>
              <PeopleCard allPeople={leaders} selectedPerson={selectedPerson} title="Loh" />
            </PeopleModal>
          )}
          {/* ############################ END MODAL POP UP ########################### */}
          <span />
        </div>
      ))}
    </Carousel>
  )
}

PeopleCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types, react/require-default-props
  id: PropTypes.arrayOf(PropTypes.object),
}

export default PeopleCarousel
