import React from 'react'
import Img from 'gatsby-image'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'
import Caption from './caption'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   imageContainer: {
      margin: theme.spacing(0, 0, 3, 0),
   },
   catptionCredit: {
      margin: theme.spacing(1, 0, 0, 0),
      lineHeight: '1',
   },
}))

const ImageWithCaption = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { alt, fluidImage, caption, credit } = props

   return (
      <div className={css.imageContainer}>
         <figure>
            <Img fluid={fluidImage} alt={alt} objectFit="contain" />
            <figcaption>
               <Caption text={caption} credit={credit} />
            </figcaption>
         </figure>
      </div>
   )
}

export default ImageWithCaption
