import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ImageOverlay from './imageOverlay'
import sharedStyles from '../styles/global'

let defaultWidth
let defaultHeight
if (typeof window !== `undefined`) {
   defaultHeight = window.innerHeight
   defaultWidth = window.innerWidth
}
const useWindowSize = () => {
   const [dimensions, setDimensions] = useState({
      windowHeight: defaultHeight,
      windowWidth: defaultWidth,
   })
   useEffect(() => {
      const handler = () =>
         setDimensions({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
         })
      window.addEventListener(`resize`, handler)
      return () => window.removeEventListener(`resize`, handler)
   }, [])
   return dimensions
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   highlightContainer: {
      position: 'relative',
      textAlign: 'center',
      width: '100%',
   },
}))

export default function Highlight(props) {
   const highlight = props.data
   const viewWidth = Math.min(useWindowSize(1200).windowWidth, 1200)
   //-- this bit of code is used to calculate the image height of a selected and non-selected image to keep with the design ratio of 174/318 (selected) and 147/318 (non-selected)
   //-- this calucation is based on viewWidth - 4*10 [spaces between images] - 2 * 5% (10% margin) /5 (the num pictures) * ratio
   const spaceBetweenImages = 10
   const marginSpace = 0.1
   const numberOfImages = 5
   const selectedRatio = 0.5471
   const nonSelectedRatio = 0.4559
   const selectedHeight =
      ((viewWidth - 4 * spaceBetweenImages - marginSpace * viewWidth) /
         numberOfImages) *
      selectedRatio
   const imageHeight =
      ((viewWidth - 4 * spaceBetweenImages - marginSpace * viewWidth) /
         numberOfImages) *
      nonSelectedRatio

   const theme = useTheme()
   const classes = useStyles(theme)

   const cssSettings = {
      crop: false,
      container: {
         position: 'relative',
         overflow: 'hidden',
         borderRadius: '5px',
         marginRight: '20px',
         transition: 'transform .5s ease',
         height: highlight.selected
            ? `${selectedHeight}px`
            : `${imageHeight}px`,
         '&:hover': highlight.selected
            ? {}
            : {
                 opacity: '70%',
                 color: '#3E63D3',
                 border: '1px solid, red',
                 height: `${selectedHeight}px`,
                 fontSize: highlight.selected ? classes.pt20 : classes.pt18,
              },
      },

      overlay: {
         background: highlight.selected
            ? 'rgba(62, 99, 211, 0.6)'
            : 'rgba(12, 29, 82, 0.7)',
      },
      text: {
         top: '50%',
         lineHeight: 1.1,
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(2),
      },
   }

   const textSize = highlight.selected ? classes.pt20 : classes.pt18
   const fontColor = highlight.selected ? '' : classes.opacity80

   return (
      <div className={classes.highlightContainer}>
         <ImageOverlay
            image={highlight.Image.localFile}
            alt={highlight.Alt}
            css={cssSettings}
         >
            <span
               className={[
                  textSize,
                  fontColor,
                  classes.uppercase,
                  classes.bold,
               ].join(' ')}
            >
               {highlight.Title}
            </span>
         </ImageOverlay>
      </div>
   )
}
